import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';

@Component({
    selector: 'app-kreatv-player',
    template: ''
})
export class KreaTVPlayerComponent implements OnInit, OnDestroy {

    @Input() cssClass: string;

    private videoplane;

    constructor(
        private renderer: Renderer2,
        @Inject('MediaPlayerHalService') private kreatvPlayerService: MediaPlayerHalInterface) {
    }

    ngOnInit() {
        this.videoplane = document.getElementById('videoplane');
        this.renderer.setStyle(this.videoplane, 'visibility', 'visible');
        if (this.cssClass) {
            this.renderer.addClass(this.videoplane, this.cssClass);
        }
        this.kreatvPlayerService.init();
    }

    ngOnDestroy() {
        this.renderer.setStyle(this.videoplane, 'visibility', 'hidden');
        if (this.cssClass) {
            this.renderer.removeClass(this.videoplane, this.cssClass);
        }
    }

}
