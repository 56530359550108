import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewImageLinkFactoryComponent } from '@kuki/global/shared/modules/preview-image-link-factory/preview-image-link-factory.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        PreviewImageLinkFactoryComponent
    ],
    exports: [
        PreviewImageLinkFactoryComponent
    ]
})
export class PreviewImageLinkFactoryModule {
}
