import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActionButton } from '@kuki/global/shared/types/button';
import { ActionKey, CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-actions-modal-v2',
    templateUrl: './actions-modal-v2.component.html'
})
export class ActionsModalV2Component implements OnInit, OnDestroy {

    private defaults = {
        ident: 'actions-modal',
        message: this.translateService.instant('MODAL.ACTIONS.MESSAGE'),
        emptyMessage: this.translateService.instant('MODAL.ACTIONS.EMPTY'),
        propagateKeys: [ CommonKeys.GRP_REC ]
    };

    public data: {
        ident: string;
        buttons: Array<ActionButton>;
        onAction: (actionButon: ActionButton, ngbActiveModal: NgbActiveModal) => Observable<any>;
        message?: string;
        emptyMessage?: string;
        propagateKeys?: Array<ActionKey>;
    };
    public locked: boolean;
    private subscription: SubscriptionObject = {};

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private ngZone: NgZone,
        private translateService: TranslateService,
        private controllerService: ControllerService) {
    }

    ngOnInit(): void {
        this.data = { ...this.defaults, ...this.data };
        this.registerControls();
    }

    public onAction(actionButton: ActionButton) {
        if (this.data.onAction) {
            this.ngZone.run(() => {
                this.locked = true;
                SOM.clearSubscriptions(this.subscription.onAction);
                this.subscription.onAction = this.data.onAction(actionButton, this.ngbActiveModal).subscribe(() => {
                    this.ngbActiveModal.close(actionButton);
                });
            });
        } else {
            this.ngbActiveModal.close(actionButton);
        }
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.data.ident, true);
        this.controllerService.registerActionKeys([ CommonKeys.GRP_BACK, CommonKeys.GRP_INFO, CommonKeys.GRP_TV ], this.data.ident, () => {
            this.ngbActiveModal.dismiss();
        });
        this.controllerService.propagateActionKeys(this.data.propagateKeys, this.data.ident);
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.data.ident);
    }

    ngOnDestroy() {
        this.unregisterControls();
    }
}
