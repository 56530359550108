import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';

@Component({
    selector: 'app-tizen-player',
    template: ''
})
export class TizenPlayerComponent implements OnInit, OnDestroy {

    private videoplane;
    @Input() cssClass: string;

    constructor(
        @Inject('MediaPlayerHalService') private tizenPlayerService: MediaPlayerHalInterface,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        // this.renderer.addClass(document.body, 'player-open');
        this.videoplane = document.getElementById('videoplane');
        this.renderer.setStyle(this.videoplane, 'visibility', 'visible');
        if (this.cssClass) {
            this.renderer.addClass(this.videoplane, this.cssClass);
        }
        this.tizenPlayerService.init();
    }

    ngOnDestroy() {
        this.renderer.setStyle(this.videoplane, 'visibility', 'hidden');
        if (this.cssClass) {
            this.renderer.removeClass(this.videoplane, this.cssClass);
        }
        // this.renderer.removeClass(document.body, 'player-open');
    }
}
