<ng-container *ngIf="!!media" [ngSwitch]="media.tile.type">
    <ng-container *ngSwitchCase="TileTypes.MEDIA">
        <app-media-player-v2 *ngIf="videoPreview"
                             [ident]="'info-panel-player'"
                             [enableControl]="false"
                             [enableOsd]="false"
                             [cssClass]="'info-panel-video'"></app-media-player-v2>
        <app-source-logo [media]="media"></app-source-logo>
        <div class="info-panel-title">{{ media.title | truncate: left ? 25 : 20 }}</div>
        <app-labels-panel *appProfileType="['classic', 'senior']" [media]="media"></app-labels-panel>
        <ng-container *appProfileType="['classic']">
            <div *ngIf="!small" class="media-description" [innerHTML]="media.description | truncate: 160"></div>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="TileTypes.BUTTON">
        <div class="info-panel-title">{{ media.title | truncate: left ? 25 : 20 }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="TileTypes.PROFILE">
        <div class="info-panel-title">{{ media.title | truncate: left ? 25 : 20 }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="TileTypes.DEVICE_TELEPORT">
        <div class="info-panel-title">{{ media.title | truncate: left ? 25 : 20 }}</div>
        <ng-container *appProfileType="['classic', 'senior']">
            <div class="labels-panel">
            <span class="label label-device-state"
                  [ngClass]="getLabelDeviceStateClass()">{{ getDeviceTeleportState() }}</span>
                <span class="label">Kuki teleport</span>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="TileTypes.CHANNEL">
        <div class="info-panel-title">{{ media.title | truncate: left ? 25 : 20 }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="TileTypes.CARROUSEL">
        <div class="info-panel-title">{{ media.title | truncate: left ? 25 : 20 }}</div>
        <app-rating *ngIf="media.tile.rating" [rating]="media.tile.rating"></app-rating>
        <ng-container *appProfileType="['classic']">
            <app-auto-scroll-container class="media-description"
                                       [type]="'vertical'">{{ media.description }}</app-auto-scroll-container>
        </ng-container>
    </ng-container>
</ng-container>

