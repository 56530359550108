<div class="keys-row" *ngFor="let keysRow of keyboard; index as y; trackBy: trackBy">
    <div class="key"
         *ngFor="let key of keysRow | removeFromArray: 'OK' : config.noOk; index as x"
         [ngClass]="getKeyClass(key, y, x)"
         appMouseEvents
         (appMouseEnter)="onMouseEnter(y, x)"
         (appMouseClick)="onClick()">
        <div class="key-bg"></div>
        <div class="key-fg">
            <span [innerHTML]="keyboardValue[y][x]"></span>
        </div>
    </div>
</div>

<!--<div style="position: fixed; top: 0; right: 0; font-size: 100px; z-index:20">{{ counter }}</div>-->
