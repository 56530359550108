import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionArrowsComponent } from './section-arrows.component';


@NgModule({
    declarations: [ SectionArrowsComponent ],
    imports: [
        CommonModule
    ],
    exports: [
        SectionArrowsComponent
    ]
})
export class SectionArrowsModule {
}
