import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';

@Component({
    selector: 'app-shaka-player',
    templateUrl: './shaka-player.component.html'
})
export class ShakaPlayerComponent implements AfterViewInit {

    @ViewChild('video') video: ElementRef;

    constructor(
        @Inject('MediaPlayerHalService') private mediaPlayerHalService: MediaPlayerHalInterface,
        private changeDetectorRef: ChangeDetectorRef,
        private renderer: Renderer2) {
    }

    ngAfterViewInit() {
        this.mediaPlayerHalService.init(this.video.nativeElement, this.renderer);
    }
}
