<div class="modal-body">
    <div class="modal-title">{{ data.message }}</div>
    <app-buttons-panel *ngIf="data.buttons && data.buttons.length > 0; else modalEmpty"
                       [type]="'vertical'"
                       [active]="true"
                       [priv]="true"
                       [buttons]="data.buttons"
                       [ident]="data.ident+'-buttons'"
                       [infinityScroll]="true"
                       [locked]="locked"
                       (action)="onAction($event)"></app-buttons-panel>
    <ng-template #modalEmpty>
        <div class="actions-modal-empty">{{ data.emptyMessage }}</div>
    </ng-template>
</div>
