import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { KeyboardConfig } from '@kuki/global/shared/types/general';
import { KeyboardModes } from '@kuki/tv/features/keyboard/keyboard.modes';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { KeyboardComponent } from '@kuki/tv/features/keyboard/keyboard.component';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnInit, OnDestroy {

    @Input() ident: string = 'form';
    @Input() keyboardIdent: string = 'form-keyboard';
    @Input() secret: boolean = false;
    @Input() placeholder: string;
    @Input() icon: string;
    @Input() value: string = '';
    @Input() keyboardConfig: KeyboardConfig;
    @Input() keyboardMode: KeyboardModes = 'normal';
    @Input() keyboardVisible: boolean;
    @Input() keyboardInfinityScroll: boolean = true;
    @Input() disableKeyboardTheme: boolean;
    @Input() priv: boolean;
    @Input() maxLength: number;

    @Output() overflow: EventEmitter<number> = new EventEmitter<number>();
    @Output() change: EventEmitter<string> = new EventEmitter<string>();
    @Output() submit: EventEmitter<string> = new EventEmitter<string>();
    @Output() keyboardVisibilityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

    private secretValue: string = '';
    public error: string;
    private keyboardComponent: KeyboardComponent;
    private active: boolean;

    constructor(
        private controllerService: ControllerService,
        private componentRegisterService: ComponentRegisterService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.componentRegisterService.registerComponent<FormComponent>(this.ident, this);
    }

    public activate() {
        if (this.active) {
            return;
        }
        this.active = true;
        this.renderer.addClass(this.elementRef.nativeElement, 'form-active');
        this.registerControls();
        if (this.keyboardVisible) {
            this.getKeyboardComponent().activate();
        }
        this.changeDetectorRef.detectChanges();
    }

    public deactivate() {
        if (!this.active) {
            return;
        }
        this.active = false;
        this.renderer.removeClass(this.elementRef.nativeElement, 'form-active');
        this.unregisterControls();
        if (this.keyboardVisible) {
            this.getKeyboardComponent().deactivate();
        }
        this.changeDetectorRef.detectChanges();
    }

    public hideKeyboard() {
        this.keyboardVisible = false;
        this.changeDetectorRef.detectChanges();
    }

    public showKeyboard() {
        this.keyboardVisible = true;
        this.keyboardVisibilityChanged.next(true);
        this.changeDetectorRef.detectChanges();
        this.getKeyboardComponent(true).activate();
    }

    public setError(error: string) {
        this.error = error;
        this.changeDetectorRef.detectChanges();
    }

    public clearError() {
        this.error = null;
    }

    public clear() {
        this.clearError();
        this.value = '';
        this.secretValue = '';
    }

    public getValue() {
        if (this.secret) {
            return this.secretValue;
        }
        return this.value;
    }

    public onKeyEnter(value: string) {
        this.value = value;
        this.change.emit(value);
        this.clearError();
        if (this.secret) {
            this.secretValue = Array(this.value.length).fill('●').join('');
        }
        this.changeDetectorRef.detectChanges();
    }

    public onKeyOk() {
        this.submit.emit(this.value);
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.ident, this.priv);
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => this.showKeyboard());
        this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => this.overflow.emit(1));
        this.controllerService.propagateActionKey(CommonKeys.GRP_BACK, this.ident);
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private getKeyboardComponent(update = false) {
        if (!this.keyboardComponent || update) {
            this.keyboardComponent = this.componentRegisterService.getComponent<KeyboardComponent>(this.keyboardIdent);
        }
        return this.keyboardComponent;
    }

    ngOnDestroy() {
        this.unregisterControls();
        this.componentRegisterService.unregisterComponent(this.ident);
    }
}
