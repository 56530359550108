import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { FormComponent } from '@kuki/tv/features/form/form.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-keyboard-modal',
    templateUrl: './keyboard-modal.component.html'
})
export class KeyboardModalComponent implements OnInit, AfterViewInit, OnDestroy {

    private formComponent: FormComponent;
    private subscription: SubscriptionObject = {};

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private controllerService: ControllerService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private componentRegisterService: ComponentRegisterService,
        private notificationService: NotificationService) {
    }

    private defaults = {
        ident: 'keyboard-modal',
        message: this.translateService.instant('MODAL.KEYBOARD.DEFAULT_MESSAGE')
    };

    public data: {
        ident: string;
        message: string
    };

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.registerControls();
    }

    ngAfterViewInit() {
        this.formComponent = this.componentRegisterService.getComponent<FormComponent>('keyboard-modal-form');
        this.formComponent.activate();
    }

    public onSubmit(text: string) {
        this.ngbActiveModal.close(text);
        /*this.subscription.checkPassword = this.getCheckApi$(password).subscribe((res: any) => {
            if (res && res.state === 'OK') {
                this.ngbActiveModal.close(password);
            }
        }, () => {
            this.formComponent.clear();
            this.formComponent.setError(this.translateService.instant('MODAL.PASSWORD.FAIL_MESSAGE'));
            this.changeDetectorRef.detectChanges();
        });*/
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.data.ident, true);
        this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.data.ident, () => this.ngbActiveModal.dismiss());
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.data.ident);
    }

    ngOnDestroy() {
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
        this.notificationService.clear();
    }
}
