import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { KeyboardModule } from '@kuki/tv/features/keyboard/keyboard.module';


@NgModule({
    declarations: [ FormComponent ],
    imports: [
        CommonModule,
        KeyboardModule,
    ],
    exports: [
        FormComponent
    ]
})
export class FormModule {
}
