import { WebosKeyMapModel } from '@kuki/global/shared/types/controller/keymap';

export class WebosKeyMap implements WebosKeyMapModel {
    UP = 38;
    DOWN = 40;
    LEFT = 37;
    RIGHT = 39;

    CH_UP = 33;
    CH_DOWN = 34;

    OK = 13;
    BACK = 461;

    PLAY = 415;
    PAUSE = 19;
    STOP = 413;
    REWIND = 412;
    FORWARD = 417;
    PREV = null;
    NEXT = null;

    REC = 416;

    N_0 = 48;
    N_1 = 49;
    N_2 = 50;
    N_3 = 51;
    N_4 = 52;
    N_5 = 53;
    N_6 = 54;
    N_7 = 55;
    N_8 = 56;
    N_9 = 57;

    COLOR_RED = 403;
    COLOR_GREEN = 404;
    COLOR_YELLOW = 405;
    COLOR_BLUE = 406;

    // Platform specific
    // TODO: Add...
    INFO = 457;
    TEXT = 459;
    SUBTITLE = 460;
    GUIDE = null;

    // PC
    PC_BACK = 8;
    PC_TURN_ON = 73;
    PC_TURN_OFF = 79;

    // GRP
    GRP_BACK = [ this.BACK, this.PC_BACK ];

    GRP_MENU = [];

    GRP_INFO = [ this.INFO, this.TEXT ];

    GRP_NUM_KEY = [
        this.N_0, this.N_1, this.N_2, this.N_3, this.N_4,
        this.N_5, this.N_6, this.N_7, this.N_8, this.N_9
    ];

    GRP_EPG = [ this.GUIDE ];

    GRP_REC = [ this.REC, this.COLOR_RED ];

    GRP_ARROW = [ this.UP, this.DOWN, this.LEFT, this.RIGHT ];

    GRP_TV = [];

    GRP_PLAY = [ this.PLAY ];
}
