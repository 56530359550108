<div class="input-group" [ngClass]="{'input-group-error': error}">
    <div class="input" [ngClass]="{'input-empty': !getValue() }">{{ getValue() }}</div>
    <div *ngIf="placeholder && !getValue() || getValue().length === 0" class="placeholder">{{ placeholder }}</div>
    <div *ngIf="icon" class="icon icon-{{icon}}"></div>
    <ng-container *ngIf="error">
        <div class="input-error-icon"><span class="icon icon-wrong-pass"></span></div>
        <div class="input-error-message">{{ error }}</div>
    </ng-container>
</div>
<app-keyboard *ngIf="keyboardVisible"
              [ident]="keyboardIdent"
              [mode]="keyboardMode"
              [config]="keyboardConfig"
              [infinityVerScroll]="keyboardInfinityScroll"
              [literal]="value"
              [priv]="priv"
              [maxLength]="maxLength"
              (keyEnter)="onKeyEnter($event)"
              (keyOk)="onKeyOk()"
              (overflow)="overflow.emit($event)"
              [ngClass]="{'disable-theme': disableKeyboardTheme }"></app-keyboard>
