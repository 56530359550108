import { DeviceTypes } from '@kuki/global/shared/types/device';
import { WebosKeyMap } from '@kuki/platforms/tv/webos/keymap';

export const hal = {
    appMode: 'TV',
    deviceType: DeviceTypes.SMART_TV,
    platform: 'TV.WEBOS',
    animationType: 'angular-anim',
    keymap: WebosKeyMap,
    videoModeDefault: null,
    afrTimeout: null,
    wsLogging: true,
    mediaPlayer: {
        mediaPlayerHal: 'SHAKA',
        playbackUrlType: '1es',
        playbackUrlFmt: 'mp4',
        playbackUrlBaseType: 'baseUrl',
        dolbyDigital: true,
        fpsInt: true,
        initOnStartup: false,
        chromecastReceiverAppId: null,
        airplay: false,
        epd: false
    },
    volume: null,
    cap: {
        connectionCheck: true,
        connectionCheckWifi: true,
        upgrader: false,
        universalLinks: false,
        watchDog: false,
        watchDogUrl: null,
        hdmioff: false,
        tvoff: false,
        videoOutputSettings: false,
        audioPassthrough: false,
        afr: false,
        mcast: false,
        rebootOnConnectionLost: false,
        powerupOnConnectionLost: false,
        ga: true,
        testTimeOnStartup: false,
        wakeOnRemote: false,
        sleepOnRemote: false,
        teleport: true,
        volumeControl: false
    }
};

