<div *ngIf="volumeBarVisible" class="volume-wrapper">
    <div class="volume-panel">
        <ng-container *ngIf="volumeAvailable; else volumeUnavailable">
            <ng-container *ngIf="muted; else volumeBar">
                <span class="volume-icon icon icon-mute"></span>
            </ng-container>
            <ng-template #volumeBar>
                <span class="volume-icon icon icon-vol-up"></span>
            </ng-template>
        </ng-container>
        <div class="volume-bar" [ngStyle]="{ width: volume + '%' }"></div>
        <ng-template #volumeUnavailable>
            <div class="volume-unvalaible-text">Hlasitost nelze měnit</div>
        </ng-template>
    </div>
</div>
