import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardComponent } from './keyboard.component';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';


@NgModule({
    declarations: [
        KeyboardComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        DirectivesModule
    ],
    exports: [
        KeyboardComponent
    ]
})
export class KeyboardModule {
}
