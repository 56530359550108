<div class="modal-body">
    <div class="modal-title">{{ data.message }}</div>
    <app-form [ident]="data.ident + '-form'"
              [keyboardIdent]="data.ident + '-form-keyboard'"
              [keyboardVisible]="true"
              keyboardMode="pin"
              [secret]="true"
              [priv]="true"
              (submit)="onSubmit($event)"></app-form>
</div>

