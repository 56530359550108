import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShakaPlayerEmbeddedComponent } from './shaka-player-embedded.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ShakaPlayerEmbeddedComponent
    ],
    exports: [
        ShakaPlayerEmbeddedComponent
    ]
})
export class ShakaPlayerEmbeddedModule {
}
