import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPanelComponent } from './info-panel.component';
import { LabelsPanelModule } from '@kuki/global/features/labels-panel/labels-panel.module';
import { SourceLogoModule } from '@kuki/global/features/source-logo/source-logo.module';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';
import { MediaPlayerModule } from '@kuki/global/features/media-player/media-player.module';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { InfoPanelService } from '@kuki/tv/features/info-panel/info-panel.service';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { RatingModule } from '@kuki/global/features/rating/rating.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';


@NgModule({
    declarations: [ InfoPanelComponent ],
    exports: [
        InfoPanelComponent
    ],
    imports: [
        CommonModule,
        LabelsPanelModule,
        SourceLogoModule,
        ImageLinkFactoryModule,
        MediaPlayerModule,
        PipesModule,
        DirectivesModule,
        RatingModule,
        AutoScrollContainerModule
    ]
})
export class InfoPanelModule {
    static forRoot(): ModuleWithProviders<InfoPanelModule> {
        return {
            ngModule: InfoPanelModule,
            providers: [
                InfoPanelService
            ]
        };
    }
}
