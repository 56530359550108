import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeletextComponent } from './teletext.component';
import { TeletextService } from '@kuki/global/features/teletext/teletext.service';

@NgModule({
    declarations: [ TeletextComponent ],
    imports: [
        CommonModule
    ],
    exports: [
        TeletextComponent
    ],
    providers: [
        TeletextService
    ]
})
export class TeletextModule {
}
