import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { SubscriptionObject, SOM } from '@kuki/global/shared/others/subscription/subscription-object';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';

@Component({
    selector: 'app-videojs-player',
    templateUrl: './videojs-player.component.html',
    styleUrls: [ './videojs-player.component.scss' ]
})
export class VideoJSPlayerComponent implements AfterViewInit, OnDestroy {

    private subscription: SubscriptionObject = {};

    @ViewChild('video', { static: true }) video: ElementRef;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Inject('MediaPlayerHalService') private videojsPlayerService: MediaPlayerHalInterface) {
    }

    ngAfterViewInit() {
        this.videojsPlayerService.init(this.video.nativeElement);
    }

    ngOnDestroy() {
        this.videojsPlayerService.destroy();
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
