import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    NgZone,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { hal } from '@kuki/platforms/hal';
import { timer } from 'rxjs';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { AndroidTVKeys, ArrisKeys, CommonKeys, TizenKeys } from '@kuki/global/shared/types/controller/keymap';
import { ConnectionCheckService } from '@kuki/global/shared/services/connection-check.service';
import { StartupService } from '@kuki/global/shared/services/startup.service';
import { SectionControlService } from '@kuki/tv/shared/modules/section-control/section-control.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { PlatformHal } from '@kuki/root/platforms/platform-hal';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { CacheControlService } from '@kuki/global/shared/services/cache-control.service';
import { WSService } from '@kuki/global/shared/services/ws.service';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { ProfileTile } from '@kuki/global/shared/types/tile';
import { ProfileIconComponent } from '@kuki/global/features/profile-icon/profile-icon.component';
import { LogEvents } from '@kuki/global/shared/types/enum';
import { LoggingService } from '@kuki/global/shared/services/logging.service';
import { ScreenSaverService } from '@kuki/global/shared/services/screen-saver.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

    @ViewChild('main', { static: false }) mainEl: ElementRef<HTMLElement>;
    @ViewChild(ProfileIconComponent, { read: ElementRef }) profileIconEl: ElementRef<HTMLElement>;

    private subscription: SubscriptionObject = {};
    public activeProfile: ProfileTile;

    constructor(
        private startupService: StartupService,
        private coreService: CoreService,
        private navigationService: NavigationService,
        private settingsService: SettingsService,
        private router: Router,
        private ngZone: NgZone,
        private controllerService: ControllerService,
        private connectionCheckService: ConnectionCheckService,
        private sectionControlService: SectionControlService,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        private profileService: ProfileService,
        private wsService: WSService,
        private loggingService: LoggingService,
        private authService: AuthService,
        private cacheControlService: CacheControlService,
        private screenSaverService: ScreenSaverService,
        private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        @Inject('PlatformHalService') private platformHalService: PlatformHal) {
        angulartics2GoogleAnalytics.startTracking();
    }

    private locked: boolean = false;
    public infoPanel: boolean;
    public volumeControl: boolean = hal.cap.volumeControl;
    public screenSaverVisible: boolean;

    ngOnInit() {
        this.coreService.watchRegister();
        this.coreService.setRenderer(this.renderer);
        this.subscription.start = this.startupService.start().subscribe(() => {
            this.coreService.initFwClass();
            this.coreService.initImageLinkFactory();
            this.coreService.initGa();
            this.coreService.initUniversalLinks();
            this.coreService.initCacheMap();
            this.coreService.initAds();
            this.coreService.watchLangChanges();
            this.coreService.watchProfileTypeChanges();
            this.coreService.watchPortalSettingsUpdates();
            this.coreService.watchPowerControl();
            this.coreService.watchVolume();
            this.coreService.watchRouter();
            this.coreService.watchHistory();
            this.coreService.watchRecord();
            this.coreService.watchExit();
            this.watchConnection();
            // register global controls
            this.registerGlobalControls();
            this.infoPanel = this.settingsService.getParsedSettingsValue<boolean>('infoPanel');
            this.coreService.initAnimations();
            this.coreService.initLangTheme();
            this.changeDetectorRef.detectChanges();
            this.coreService.setMainEl(this.mainEl);
            this.coreService.initTvPlatforms();
            this.coreService.initSectionControl();
            this.coreService.initScreenSaver();
            this.activeProfile = this.profileService.getActiveProfile();
            this.changeDetectorRef.detectChanges();
        }, (error) => {
            console.log('Can\'t start app');
            console.error(error);
        });

        this.subscription.onProfileChanged = this.profileService.onProfileChanged$
            .subscribe(() => {
                this.coreService.initProfileTypeTheme();
                this.coreService.initAnimations();
                if (this.isReady()) {
                    this.activeProfile = { ...this.profileService.getActiveProfile() };
                    this.changeDetectorRef.detectChanges();
                }
            });
        this.subscription.onProfileIconChanged = this.profileService.onProfileIconChanged$
            .subscribe(() => {
                this.activeProfile = { ...this.profileService.getActiveProfile() };
            });

        this.subscription.onScreenSaver = this.screenSaverService.onScreenSaverShow$.subscribe((visible) => {
            this.screenSaverVisible = visible;
            this.changeDetectorRef.detectChanges();
        });
    }

    private registerGlobalControls() {
        this.controllerService.registerGlobalKey(CommonKeys.GRP_BACK, () => {
            this.ngZone.run(() => {
                if (this.subscription.navBackDebounce) {
                    return;
                }
                this.navigationService.navigateBack();
                this.subscription.navBackDebounce = timer(300).subscribe(() => {
                    this.subscription.navBackDebounce = null;
                });
            });
        });
        this.controllerService.registerGlobalKey(CommonKeys.GRP_BACK, () => {
            this.ngZone.run(() => {
                this.navigationService.navigateHome(false);
            });
        }, 'plo');

        this.controllerService.registerGlobalKey(CommonKeys.GRP_EPG, () => {
            this.ngZone.run(() => {
                this.navigationService.navigateEpg();
            });
        });

        if (hal.platform === 'TV.ANDROID') {
            this.controllerService.registerGlobalKeys([ AndroidTVKeys.PHILIPS_TV ], () => {
                this.ngZone.run(() => {
                    this.navigationService.navigateHome(false);
                });
            });
            this.controllerService.registerGlobalKey(AndroidTVKeys.SEARCH, () => {
                this.ngZone.run(() => {
                    this.navigationService.navigateSearch();
                });
            });
        }

        if (hal.platform === 'TV.TIZEN') {
            this.controllerService.registerGlobalKey(TizenKeys.CHLIST, () => {
                this.ngZone.run(() => {
                    this.navigationService.navigateHome(false);
                });
            });
        }

        if (hal.platform === 'TV.ARRIS') {
            this.controllerService.registerGlobalKey(ArrisKeys.SEARCH, () => {
                this.ngZone.run(() => {
                    this.navigationService.navigateSearch();
                });
            });
            this.controllerService.registerGlobalKey(ArrisKeys.VOD, () => {
                this.ngZone.run(() => {
                    this.navigationService.navigateVod();
                });
            });
            this.controllerService.registerGlobalKey(CommonKeys.GRP_MENU, () => {
                this.ngZone.run(() => {
                    this.navigationService.navigateHome(false);
                });
            });
        }
    }

    private unregisterGlobalControls() {
        this.controllerService.unregisterGlobalLevel();
    }

    private watchConnection() {
        this.subscription.connectionDown$ = this.connectionCheckService.connectionDown$.subscribe(() => {
            this.unregisterGlobalControls();
            this.controllerService.registerGlobalKey(CommonKeys.GRP_BACK, () => {
                this.navigationService.requestExit();
            });
            if (!this.coreService.isExitModalOpened()) {
                this.controllerService.lockStackControl();
            }
            this.locked = true;
        });
        this.subscription.connectionUp$ = this.connectionCheckService.connectionUp$.subscribe(() => {
            this.controllerService.unregisterGlobalActionKey(CommonKeys.GRP_BACK);
            this.registerGlobalControls();
            this.controllerService.unlockStackControl();
            this.locked = false;
        });
    }

    public isReady() {
        return this.startupService.isReady();
    }

    public isRendered() {
        return !!this.coreService.getMainElement();
    }

    public isBeta() {
        return hal.platform === 'TV.ARRIS' && this.platformHalService.getDeviceModel() === 'vip1003';
    }

    @HostListener('window:beforeunload')
    beforeUnloadHander() {
        SOM.clearSubscriptionsObject(this.subscription);
        this.wsService.sendPowerChange(this.authService.serial, false);
        this.profileService.deactivateProfileProcess(false).subscribe({
            error: () => {
            }
        });
        this.loggingService.log(LogEvents.EXIT);
        this.coreService.destroy();
    }
}
