import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { fromEvent } from 'rxjs';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CoreService } from '@kuki/global/shared/services/core.service';

@Component({
    selector: 'app-mouse-control',
    templateUrl: './mouse-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MouseControlComponent implements OnInit, OnDestroy {

    private subscription: SubscriptionObject = {};
    public mouseControlUpEnabled: boolean = false;
    public mouseControlDownEnabled: boolean = false;

    constructor(
        private controllerService: ControllerService,
        private coreService: CoreService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscription.mouseUpEvent = fromEvent(document, 'mouseup').subscribe(() => {
            this.controllerService.triggerKeyUpEvent();
        });

        this.subscription.onMouseControlEnabled = this.coreService.onMouseControlEnabled$.subscribe((enabled) => {
            this.mouseControlUpEnabled = enabled;
            this.mouseControlDownEnabled = enabled;
            this.changeDetectorRef.detectChanges();
        });

        this.subscription.onMouseControlUpEnabled = this.coreService.onMouseControlUpEnabled$.subscribe((enabled) => {
            this.mouseControlUpEnabled = enabled;
            this.changeDetectorRef.detectChanges();
        });

        this.subscription.onMouseControlDownEnabled = this.coreService.onMouseControlDownEnabled$.subscribe((enabled) => {
            this.mouseControlDownEnabled = enabled;
            this.changeDetectorRef.detectChanges();
        });
    }

    public onControlUp() {
        this.controllerService.emulatePressByActionKey(CommonKeys.UP);
    }

    public onControlDown() {
        this.controllerService.emulatePressByActionKey(CommonKeys.DOWN);
    }

    public onControlLeft() {
        this.controllerService.emulatePressByActionKey(CommonKeys.LEFT);
    }

    public onControlRight() {
        this.controllerService.emulatePressByActionKey(CommonKeys.RIGHT);
    }

    public onControlOk() {
        this.controllerService.emulatePressByActionKey(CommonKeys.OK);
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
