<div class="mouse-control-block" appMouseMoveVisible>
    <div *ngIf="mouseControlUpEnabled"
         class="mouse-control mouse-control-up"
         appMouseEvents
         (appMouseClick)="onControlUp()"></div>
    <div *ngIf="mouseControlDownEnabled"
         class="mouse-control mouse-control-down"
         appMouseEvents
         (appMouseClick)="onControlDown()"></div>
    <!--    <div class="mouse-control mouse-control-left"-->
    <!--         appMouseEvents-->
    <!--         (appMouseClick)="onControlLeft()"></div>-->
    <!--    <div class="mouse-control mouse-control-right"-->
    <!--         appMouseEvents-->
    <!--         (appMouseClick)="onControlRight()"></div>-->
    <!--    <div class="mouse-control mouse-control-ok"-->
    <!--         appMouseEvents-->
    <!--         (appMouseClick)="onControlOk()">OK</div>-->
</div>
