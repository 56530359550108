import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ImageLinkFactoryService } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CarrouselService } from '@kuki/global/shared/services/carrousel.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { ScreenSaverService } from '@kuki/global/shared/services/screen-saver.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { ImageTypes, TileTypes } from '@kuki/global/shared/types/enum';
import { LocationInfo, News, TileRow } from '@kuki/global/shared/types/general';
import { ProfileTile } from '@kuki/global/shared/types/tile';
import { CarrouselTile } from '@kuki/global/shared/types/tile/carrousel-tile';
import { Angulartics2 } from 'angulartics2';
import { forkJoin, timer } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { VisibleService } from '../../../global/shared/modules/visible/visible.service';
import { CoreService } from '../../../global/shared/services/core.service';

@Component({
    selector: 'app-screen-saver',
    templateUrl: './screen-saver.component.html'
})
export class ScreenSaverComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionObject = {};
    private readonly CARROUSEL_IDENT: string = 'k2dash';
    private readonly CARROUSEL_ROTATE_INTERVAL: number = 15000;
    public carrouselList: TileRow<CarrouselTile>;

    public activeSlideIndex: number = 0;
    private ident: string = 'screen-saver';

    public news: Array<News>;
    private activeNewsIndex: number = 0;
    public currentDayname: string;
    public isBrPosition: boolean;
    public currentLocationInfo: LocationInfo;

    public ImageTypes = ImageTypes;
    public time = new Date();

    public activeProfile: ProfileTile;

    constructor(
        private controllerService: ControllerService,
        private navigationService: NavigationService,
        private carrouselService: CarrouselService,
        private screenSaverService: ScreenSaverService,
        private imageLinkFactoryService: ImageLinkFactoryService,
        private visibleService: VisibleService,
        private profileService: ProfileService,
        private coreService: CoreService,
        private ngZone: NgZone,
        private angulartics2: Angulartics2,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.subscriptions.getCarrouselList = this.carrouselService.getCarrouselList(this.CARROUSEL_IDENT)
            .subscribe((carrouselList) => {
                this.carrouselList = carrouselList;
                this.carrouselList.tiles.unshift({ type: TileTypes.CARROUSEL, general: true, guid: 'GENERAL' });
                this.changeDetectorRef.detectChanges();
                this.registerControls();
            });
        this.initRotation();
        this.subscriptions.fetchLoc = forkJoin([ this.fetchNews(), this.fetchCurrentDayname(), this.fetchCurrentLocationInfo() ])
            .subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });

        this.subscriptions.timeTimer = timer(0, 1000)
            .pipe(
                tap(() => {
                    this.time = new Date();
                    this.changeDetectorRef.detectChanges();
                }),
                throttleTime(5000)
            )
            .subscribe(() => {
                if (this.activeNewsIndex === this.news?.length - 1) {
                    this.activeNewsIndex = 0;
                } else {
                    this.activeNewsIndex++;
                }
            });

        this.activeProfile = this.profileService.getActiveProfile() ? { ...this.profileService.getActiveProfile() } : undefined;
        document.body.classList.add('screen-saver-activated');
    }

    private initRotation() {
        SOM.clearSubscriptions(this.subscriptions.carrouselRotateInterval);
        let i = 0;
        this.ngZone.runOutsideAngular(() => {
            this.subscriptions.carrouselRotateInterval = timer(this.CARROUSEL_ROTATE_INTERVAL, this.CARROUSEL_ROTATE_INTERVAL)
                .subscribe(() => {
                    if (i === 1) {
                        this.isBrPosition = !this.isBrPosition;
                        i = 0;
                    } else {
                        i++;
                    }
                    this.moveToNextSlide();
                });
        });
    }

    public getScreenSaverScreenStyles() {
        const activeScreen = this.carrouselList.tiles[ this.activeSlideIndex ];
        if (!activeScreen || activeScreen.general) {
            return;
        }
        const imageType = ImageTypes.CARROUSEL;
        const type = 'fullscreen';
        const mediaUrl = `url(${ this.imageLinkFactoryService.generateUrl(imageType, type, activeScreen.backdrop) })`;
        return {
            'background-image': mediaUrl
        };
    }

    public getNews(): News {
        return this.news && this.news[ this.activeNewsIndex ];
    }

    private registerControls() {
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => {
            this.makeBtnAction();
        });
        this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.ident, () => {
            this.screenSaverService.destroy();
        });
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private moveToPrevSlide() {
        if (!this.carrouselList) {
            return;
        }
        if (this.activeSlideIndex <= 0) {
            this.activeSlideIndex = this.carrouselList.tiles.length - 1;
        } else {
            this.activeSlideIndex--;
        }
        this.changeDetectorRef.detectChanges();
    }

    private moveToNextSlide() {
        if (!this.carrouselList) {
            return;
        }
        if (this.activeSlideIndex >= this.carrouselList.tiles.length - 1) {
            this.activeSlideIndex = 0;
        } else {
            this.activeSlideIndex++;
        }
        if (this.carrouselList.tiles[ this.activeSlideIndex ].general) {
            this.visibleService.show();
        } else {
            this.visibleService.hide('npvr-stats', 'beta-label', 'time', 'profile-icon');
        }
        this.changeDetectorRef.detectChanges();
    }

    private makeBtnAction() {
        if (!this.carrouselList) {
            return;
        }
        const activeSlide = this.carrouselList.tiles[ this.activeSlideIndex ];
        if (!activeSlide) {
            return;
        }
        this.angulartics2.eventTrack.next({
            action: 'click',
            properties: {
                category: 'carrousel',
                label: activeSlide.btnText,
                value: activeSlide.label
            }
        });
        this.ngZone.run(() => {
            return this.navigationService.navigateOnAction(activeSlide.btnAction, { tileRow: this.carrouselList, tile: activeSlide });
        });
    }

    private fetchNews() {
        return this.screenSaverService.getNews().pipe(
            tap((news) => {
                this.news = news;
            })
        );
    }

    private fetchCurrentDayname() {
        return this.screenSaverService.getCurrentDayname().pipe(
            tap((currentDayname) => {
                this.currentDayname = currentDayname;
            })
        );
    }

    private fetchCurrentLocationInfo() {
        return this.screenSaverService.getCurrentLocationInfo().pipe(
            tap((currentLocationInfo) => {
                this.currentLocationInfo = currentLocationInfo;
            })
        );
    }

    ngOnDestroy() {
        this.visibleService.show();
        SOM.clearSubscriptionsObject(this.subscriptions);
        this.unregisterControls();
        document.body.classList.remove('screen-saver-activated');
    }
}
