import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { ArrisKeys } from '@kuki/global/shared/types/controller/keymap';
import { VolumeService } from '@kuki/global/features/volume-v2/volume.service';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { LoggingService } from '@kuki/global/shared/services/logging.service';

@Component({
    selector: 'app-volume',
    templateUrl: './volume.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VolumeComponent implements OnInit, OnDestroy {

    private readonly HIDE_TIMEOUT = 1000;
    private ident: string = 'volume';
    public volumeBarVisible: boolean;

    private subscription: SubscriptionObject = {};
    public volumeAvailable: boolean;
    public volume: number;
    public muted: boolean;

    private controllerRegistered: boolean;

    constructor(
        private ngZone: NgZone,
        private volumeService: VolumeService,
        private loggingService: LoggingService,
        private controllerService: ControllerService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.volumeService.init();
        this.registerControls();
        this.controllerRegistered = true;

        this.subscription.volumeAvailable = this.volumeService.volumeAvailable$.subscribe((volumeAvailable) => {
            this.volumeAvailable = volumeAvailable;
        });
        this.ngZone.runOutsideAngular(() => {
            this.subscription.volumeChanged = this.volumeService.volumeChanged$
                .pipe(
                    switchMap((hideTimeout: number) => {
                        hideTimeout = hideTimeout || this.HIDE_TIMEOUT;
                        this.volumeBarVisible = true;
                        if (this.volumeAvailable) {
                            this.volume = this.volumeService.getVolume();
                            this.muted = this.volumeService.getMuted();
                        }
                        this.changeDetectorRef.detectChanges();
                        return timer(hideTimeout);
                    })
                )
                .subscribe(() => {
                    this.volumeBarVisible = false;
                    this.changeDetectorRef.detectChanges();
                });
        });
    }

    private registerControls() {
        this.controllerService.registerGlobalKey(ArrisKeys.VOLUME_UP, () => this.volUp());
        this.controllerService.registerGlobalKey(ArrisKeys.VOLUME_UP, () => this.volUp(), 'h');
        this.controllerService.registerGlobalKey(ArrisKeys.VOLUME_DOWN, () => this.volDown());
        this.controllerService.registerGlobalKey(ArrisKeys.VOLUME_DOWN, () => this.volDown(), 'h');
        this.controllerService.registerGlobalKey(ArrisKeys.MUTE, () => this.toggleMute());
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private volUp() {
        this.volumeService.volUp();
    }

    private volDown() {
        this.volumeService.volDown();
    }

    private toggleMute() {
        if (this.volumeService.getMuted()) {
            this.volumeService.unmute();
        } else {
            this.volumeService.mute();
        }
    }

    ngOnDestroy() {
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
