import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { TranslateService } from '@ngx-translate/core';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { FormComponent } from '@kuki/tv/features/form/form.component';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ProfileService } from '@kuki/global/shared/services/profile.service';

@Component({
    selector: 'app-password-modal',
    templateUrl: './password-modal.component.html'
})
export class PasswordModalComponent implements OnInit, AfterViewInit, OnDestroy {

    private formComponent: FormComponent;
    private subscription: SubscriptionObject = {};

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private authService: AuthService,
        private controllerService: ControllerService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private componentRegisterService: ComponentRegisterService,
        private profileService: ProfileService,
        private notificationService: NotificationService) {
    }

    private defaults = {
        ident: 'password-modal',
        message: this.translateService.instant('MODAL.PASSWORD.MESSAGE')
    };

    public data: {
        ident: string;
        message: string;
        type: 'device' | 'profile' | 'profile-pin';
        profileId: number;
    };

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.registerControls();
    }

    ngAfterViewInit() {
        this.formComponent = this.componentRegisterService.getComponent<FormComponent>('password-modal-form');
        this.formComponent.activate();
    }

    public onSubmit(password: string) {
        this.subscription.checkPassword = this.getCheckApi$(password).subscribe((res: any) => {
            if (res && res.state === 'OK') {
                this.ngbActiveModal.close(password);
            }
        }, () => {
            this.formComponent.clear();
            this.formComponent.setError(this.translateService.instant('MODAL.PASSWORD.FAIL_MESSAGE'));
            this.changeDetectorRef.detectChanges();
        });
    }

    private getCheckApi$(password) {
        if (this.data) {
            if (this.data.type === 'profile') {
                return this.profileService.checkPassword(this.data.profileId, password);
            } else if (this.data.type === 'profile-pin') {
                return this.profileService.checkProfilePin(this.data.profileId, password);
            }
        }
        return this.authService.checkPassword(password);
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.data.ident, true);
        this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.data.ident, () => this.ngbActiveModal.dismiss());
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.data.ident);
    }

    ngOnDestroy() {
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
        this.notificationService.clear();
    }
}
