<div class="modal-title">{{ data.message }} <ng-container *ngIf="timeoutTimer">({{ timeoutTimer }})</ng-container></div>
<div class="buttons-panel">
    <div class="button"
         [ngClass]="{ 'button-active': isButtonActive(1) }"
         appMouseEvents
         (appMouseEnter)="onMouseEnter(1)"
         (appMouseClick)="onClick(1)">
        <div class="button-label">{{ data.btn1 }}</div>
    </div>
    <div class="button"
         [ngClass]="{ 'button-active': isButtonActive(2) }"
         appMouseEvents
         (appMouseEnter)="onMouseEnter(2)"
         (appMouseClick)="onClick(2)">
        <div class="button-label">{{ data.btn2 }}</div>
    </div>
</div>
