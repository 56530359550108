import { ApplicationRef, Injectable } from '@angular/core';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { ActionButton } from '@kuki/global/shared/types/button';
import { Actions } from '@kuki/global/shared/types/enum';
import { Errors } from '@kuki/global/shared/types/enum/errors';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { ActionsModalV2Component } from '@kuki/tv/modals/actions-modal-v2/actions-modal-v2.component';
import { ActionsModalComponent } from '@kuki/tv/modals/actions-modal/actions-modal.component';
import { ConfirmModalComponent } from '@kuki/tv/modals/confirm-modal/confirm-modal.component';
import { PasswordModalComponent } from '@kuki/tv/modals/password-modal/password-modal.component';
import { PinModalComponent } from '@kuki/tv/modals/pin-modal/pin-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { KeyboardModalComponent } from './keyboard-modal/keyboard-modal.component';

@Injectable()
export class ModalsService implements ModalsInterface {

    private modalRef: NgbModalRef;

    constructor(
        private ngbModal: NgbModal,
        private applicationRef: ApplicationRef) {
    }

    public openPasswordModal(data?: any): Promise<string> {
        return this.openModal(PasswordModalComponent, data, 'password-modal');
    }

    public openPinModal(data?: any): Promise<string> {
        return this.openModal(PinModalComponent, data, 'pin-modal');
    }

    public openConfirmModal(data?: any): Promise<boolean> {
        return this.openModal(ConfirmModalComponent, data, 'confirm-modal ' + (data ? data.cssClass : ''));
    }

    public openActionsModal(data?: { type?: string, tile?: MediaTile }): Promise<Actions> {
        return this.openModal(ActionsModalComponent, data, 'actions-modal actions-modal-' + data?.type);
    }

    public openKeyboardModal(data?: any): Promise<string> {
        return this.openModal(KeyboardModalComponent, data, 'keyboard-modal');
    }

    public openActionsModalV2(data?: {
        buttons: Array<ActionButton>,
        onAction: (actionButton: ActionButton) => Observable<any>
    }): Promise<ActionButton> {
        const buttonsCss = Math.min(data.buttons.length, 5);
        return this.openModal(ActionsModalV2Component, data, 'actions-modal actions-modal-' + buttonsCss);
    }

    private openModal(modalComponent: any, data: any, windowClass?: string) {
        if (this.modalRef) {
            this.modalRef.dismiss();
        }
        this.modalRef = this.ngbModal.open(modalComponent, { windowClass: windowClass, keyboard: false, backdrop: 'static' });
        this.modalRef.componentInstance.data = data;
        this.applicationRef.tick();
        return this.modalRef.result.catch(() => {
            throw new Error(Errors.MODAL_CLOSE);
        }).finally(() => {
            this.modalRef = undefined;
        });
    }
}
