<app-image-link-factory [url]="tile.image"
                        [imageType]="ImageType.PROFILE_IMAGE"
                        [type]="'tile'"
                        [noGrad]="noGrad"></app-image-link-factory>
<app-auto-scroll-container class="tile-label"
                           [active]="focus">{{ tile.label }}</app-auto-scroll-container>

<div *ngIf="showActions()" class="tile-actions">
    <div class="tile-actions-left">
        <div class="tile-action" (click)="onEditProfile($event)"><span class="icon icon-pen"></span></div>
    </div>
    <div class="tile-actions-center">
        <div class="tile-action tile-action-select-profile">
            <span [class]="tileProfileActive ? 'icon icon-remove-circle' : 'icon icon-check-circle'"></span></div>
    </div>
    <div class="tile-actions-right">
        <div class="tile-action" (click)="onDeleteProfile($event)"><span class="icon icon-trash"></span></div>
    </div>
</div>
