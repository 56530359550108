import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KreaTVPlayerComponent } from './kreatv-player.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        KreaTVPlayerComponent
    ],
    exports: [
        KreaTVPlayerComponent
    ]
})
export class KreaTVPlayerModule {
}
