<div class="screen-saver-slide"
     *ngIf="carrouselList?.tiles[activeSlideIndex]; let slide"
     [ngStyle]="getScreenSaverScreenStyles()">
    <div *ngIf="slide.general; else bannerSlide" class="slide-general">
        <div *ngIf="activeProfile" class="profile">
            <div class="profile-label">{{ activeProfile.label }}</div>
            <app-profile-tile [tile]="activeProfile" [noGrad]="true"></app-profile-tile>
        </div>
        <div class="date-dayname">
            <div class="vcenter">
                <div class="date-dayname-box">
                    <div class="date">{{ time | date:'dd. MM. yyyy' }}</div>
                    <div class="dayname">{{ currentDayname }}</div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="currentLocationInfo">
            <div class="weather">
                <div class="vcenter">
                    <div class="weather-box">
                        <div class="weather-data">
                            <div class="temperature">{{ currentLocationInfo.weatherTemp }}°</div>
                            <div class="weather-icon">
                                <app-image-link-factory [url]="currentLocationInfo.weatherConditionIconImage"
                                                        [imageType]="ImageTypes.WEATHER"
                                                        type="screensaver"></app-image-link-factory>
                            </div>
                        </div>
                        <div class="city">{{ currentLocationInfo.name }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="getNews(); let newsItem" class="news">
            {{ newsItem.pubdate }}: {{ newsItem.text }}
        </div>
    </div>
    <ng-template #bannerSlide>
        <div class="screen-saver-title">{{ slide.label }}</div>
        <app-rating [rating]="slide.rating"></app-rating>
        <app-auto-scroll-container class="screen-saver-description"
                                   type="vertical">{{ slide.description }}</app-auto-scroll-container>
        <div *ngIf="slide.btnText && slide.btnText.length > 0" class="screen-saver-button button">
            <div class="icon icon-play-button-big"></div>
            <div class="button-label">{{ slide.btnText }}</div>
        </div>
    </ng-template>
</div>
