import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RegisterGuardService } from '@kuki/global/shared/services/register-guard.service';
import { ProfileGuardService } from '@kuki/global/shared/services/profile-guard.service';

const appRoutes: Routes = [
    {

        path: '',
        canActivate: [
            RegisterGuardService,
            // ProfileGuardService
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('app/tv/sections/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'program-board',
                loadChildren: () => import('app/tv/sections/program-board/program-board.module').then(m => m.ProgramBoardModule)
            },
            {
                path: 'media-board',
                loadChildren: () => import('app/tv/sections/media-board/media-board.module').then(m => m.MediaBoardModule)
            },
            {
                path: 'media-detail',
                loadChildren: () => import('app/tv/sections/media-detail/media-detail.module').then(m => m.MediaDetailModule)
            },
            // {
            //     path: 'media-player',
            //     loadChildren: () => import('app/global/sections/media-player-section/media-player-section.module')
            //         .then(m => m.MediaPlayerSectionModule)
            // },
            {
                path: 'media-player',
                loadChildren: () => import('app/global/sections/media-player-section-v2/media-player-section-v2.module')
                    .then(m => m.MediaPlayerSectionV2Module)
            },
            {
                path: 'search',
                loadChildren: () => import('app/tv/sections/search/search.module').then(m => m.SearchModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('@kuki/tv/sections/settings/settings.module').then(m => m.SettingsModule)
            }
        ]
    },
    {
        path: 'profile',
        canActivate: [
            RegisterGuardService,
        ],
        loadChildren: () => import('app/tv/sections/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'wizard',
        loadChildren: () => import('app/tv/sections/wizard/wizard.module').then(m => m.WizardModule)
    },
    {
        path: 'self-test',
        loadChildren: () => import('@kuki/global/sections/self-test-section/self-test-section.module').then(m => m.SelfTestSectionModule)
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                preloadingStrategy: PreloadAllModules
            }
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
