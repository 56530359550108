import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MouseControlComponent } from './mouse-control.component';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';


@NgModule({
    declarations: [ MouseControlComponent ],
    imports: [
        CommonModule,
        DirectivesModule
    ],
    exports: [
        MouseControlComponent
    ]
})
export class MouseControlModule {
}
