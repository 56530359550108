import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VideoJSPlayerModule } from '@kuki/global/features/media-player/media-player-hals/videojs-player/videojs-player.module';
import { ExoPlayerModule } from '@kuki/global/features/media-player/media-player-hals/exo-player/exo-player.module';
import { TizenPlayerModule } from '@kuki/global/features/media-player/media-player-hals/tizen-player/tizen-player.module';
import { KreaTVPlayerModule } from '@kuki/global/features/media-player/media-player-hals/kreatv-player/kreatv-player.module';
import { ShakaPlayerModule } from '@kuki/global/features/media-player/media-player-hals/shaka-player/shaka-player.module';
import { TeletextModule } from '@kuki/global/features/teletext/teletext.module';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { OsdModule } from '@kuki/global/features/osd/osd.module';
import { ArrowBackModule } from '@kuki/web/features/arrow-back/arrow-back.module';
import { CountdownBoxModule } from '../countdown-box/countdown-box.module';
import { MediaPlayerV2Component } from './media-player-v2/media-player-v2.component';
import { ShakaPlayerEmbeddedModule } from './media-player-hals/shaka-player-embedded/shaka-player-embedded.module';
import { ProgressButtonModule } from '@kuki/global/features/progress-button/progress-button.module';

@NgModule({
    imports: [
        CommonModule,
        OsdModule,
        VideoJSPlayerModule,
        ExoPlayerModule,
        TizenPlayerModule,
        KreaTVPlayerModule,
        ShakaPlayerModule,
        ShakaPlayerEmbeddedModule,
        TeletextModule,
        DirectivesModule,
        TranslateModule,
        ArrowBackModule,
        ProgressButtonModule,
        CountdownBoxModule,
        FormsModule
    ],
    declarations: [
        MediaPlayerV2Component
    ],
    exports: [
        MediaPlayerV2Component
    ],
    providers: []
})
export class MediaPlayerModule {
}
