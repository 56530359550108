export const keyboardMaps = {
    'normal': [
        [ 'q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p' ],
        [ 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l' ],
        [ 'BCKSP', 'y', 'x', 'c', 'v', 'b', 'n', 'm', 'OK'],
        [ 'MODE', 'CAPS', 'SPACE' ]
    ],
    'alt': [
        [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0' ],
        [ '@', '#', '$', '%', '^', '&', '-', '+', '(', ')' ],
        [ 'BCKSP', '_', '/', ',', '.', '!', '?', '*', 'OK' ],
        [ 'MODE', 'SPACE' ]
    ],
    'pin': [
        [ '1', '2', '3' ],
        [ '4', '5', '6 '],
        [ '7', '8', '9'],
        [ '0', 'BCKSP', 'OK' ]
    ]
};
