import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { hal } from '@kuki/platforms/hal';
import { Injectable, NgZone } from '@angular/core';
import { environment } from '@kuki/environments/environment';

@Injectable()
export class TeletextService {

    private teletextUrl: string;
    private teletextWsUrl: string;
    private subscription: SubscriptionObject = {};
    private ident;
    private sign;
    private expires;

    private readonly HOME_PAGE = 100;
    private readonly SUBPAGE_INTERVAL = 8000;
    public transparent: boolean = false;
    public paused: boolean = false;
    private currentPage: number;
    private pageList: Array<any> = [];

    private subPages: Array<any> = [];
    private currSubPage: number = 0;

    private ws: WebSocketSubject<any>;
    private currentDigitEntry: string = '';

    private teletextHtml: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public teletextHtml$: Observable<string> = this.teletextHtml.asObservable();

    private digit: Subject<string> = new Subject<string>();
    public digit$: Observable<string> = this.digit.asObservable();

    private transparencyChanged: Subject<void> = new Subject<void>();
    public transparencyChanged$: Observable<void> = this.transparencyChanged.asObservable();

    constructor(private httpClient: HttpClient,
                private ngZone: NgZone,
                private portalSettingsService: PortalSettingsService) {
    }

    public init(ident: string) {
        this.setTransparent(false);
        this.setPause(false);
        this.ident = ident;
        const portalSettings = this.portalSettingsService.getPortalSettings();
        this.teletextUrl = hal.platform === 'TV.ARRIS' ? portalSettings.teletext.urlStb : portalSettings.teletext.url;
        this.teletextWsUrl = hal.platform === 'TV.ARRIS' ? portalSettings.teletext.wsStb : portalSettings.teletext.ws;
        this.subscription.signTeletext = this.httpClient.post<any>(`${ environment.apiUrl }teletext/sign`, {
            ident: this.ident
        }).pipe(switchMap((signdata) => {
            this.sign = signdata.sign.sign;
            this.expires = signdata.sign.expires;
            return this.httpClient.get<any>(this.teletextUrl + this.ident + '/index.json', {
                params: {
                    sign: this.sign,
                    expires: this.expires
                }
            });
        })).subscribe((data) => {
            if (this.ws) {
                this.ws.complete();
                this.ws = null;
            }
            this.ws = webSocket(`${ this.teletextWsUrl }${ this.ident }/listen?sign=${ this.sign }&expires=${ this.expires }`);
            this.ws.subscribe((msg) => {
                if (msg.msg === 'index') {
                    this.pageList = data;
                }
                if (msg.msg === 'update' && msg.data === this.currentPage) {
                    this.loadPage(this.currentPage, true);
                }
            });
            this.pageList = data;
            this.loadPage(this.HOME_PAGE);
        }, (err) => {
            console.log('getting/submitting teletext index - error: ' + err.statusText);
        });
    }

    public destroy() {
        if (this.ws) {
            this.ws.complete();
            this.ws = null;
        }
        this.currentDigitEntry = '';
        SOM.clearSubscriptionsObject(this.subscription);
        this.destroyPage();
    }


    public start(channelId: number) {
        return this.httpClient.post(`${ environment.apiUrl }teletext/start`, {
            channel_pk: channelId
        });
    }

    public stop() {
        return this.httpClient.post(`${ environment.apiUrl }teletext/stop`, undefined);
    }

    private destroyPage(justRefresh: boolean = false) {
        if (this.subscription.loading) {
            SOM.clearSubscriptions(this.subscription.loading);
        }
        if (!justRefresh) {
            this.stopSubPageInterval();
            this.subPages = [];
            this.currSubPage = 0;
        }
    }

    private loadPage(page: number, justRefresh: boolean = false) {
        this.destroyPage(justRefresh);
        this.currentPage = page;
        if (this.subscription.loading) {
            SOM.clearSubscriptions(this.subscription.loading);
            this.subscription.loading = null;
        }
        this.subscription.loading = this.httpClient.get<any>(`${ this.teletextUrl }${ this.ident }/${ page }.json`, {
            params: {
                sign: this.sign,
                expires: this.expires
            }
        }).subscribe((data) => {
            this.subPages = data.subpages;
            if (justRefresh) {
                if (this.currSubPage >= this.subPages.length) {
                    this.currSubPage = 0;
                }
            } else {
                this.currSubPage = 0;
            }
            this.showSubPage(this.currSubPage);
            if (!justRefresh) {
                this.setPause(false);
            }
        }, (err) => {
            console.log('getting page - error: ' + err.statusText);
        });
    }

    private showSubPage(subPage: number) {
        console.log(`showing ${ subPage } / ${ this.subPages.length }`);
        if (subPage < this.subPages.length) {
            this.teletextHtml.next(this.subPages[ subPage ]);
        }
    }

    public setTransparent(transparent) {
        this.transparent = transparent;
        this.transparencyChanged.next();
    }

    public setPause(pause: boolean) {
        this.paused = pause;
        this.startSubPageInterval();
    }

    private stopSubPageInterval() {
        if (this.subscription.subpageInterval) {
            SOM.clearSubscriptions(this.subscription.subpageInterval);
            this.subscription.subpageInterval = null;
        }
    }

    private startSubPageInterval() {
        this.stopSubPageInterval();
        if (!this.paused) {
            if (this.subPages.length > 1) {
                this.ngZone.runOutsideAngular(() => {
                    this.subscription.subpageInterval = timer(this.SUBPAGE_INTERVAL, this.SUBPAGE_INTERVAL).subscribe(() => {
                        this.currSubPage++;
                        if (this.currSubPage >= this.subPages.length) {
                            this.currSubPage = 0;
                        }
                        this.showSubPage(this.currSubPage);
                    });
                });
            }
        }
    }

    public gotoNextPage() {
        this.gotoRelPage(this.currentPage, 1);
    }

    public gotoPreviousPage() {
        this.gotoRelPage(this.currentPage, -1);
    }

    public gotoNextMag() {
        this.gotoRelMag(1);
    }

    public gotoPreviousMag() {
        this.gotoRelMag(-1);
    }

    public gotoIndexPage() {
        this.loadPage(this.HOME_PAGE);
    }

    private gotoRelPage(basePage: number, dir: number) {
        if (!this.pageList.length) {
            return;
        }
        let wantPage = basePage + dir;
        while (this.pageList.indexOf(wantPage) === -1) {
            wantPage += dir;
            if (wantPage > Math.max(...this.pageList)) {
                wantPage = Math.min(...this.pageList);
            }
            if (wantPage < Math.min(...this.pageList)) {
                wantPage = Math.max(...this.pageList);
            }
        }
        this.loadPage(wantPage);
    }

    // mag === magazine
    private gotoRelMag(dir: number) {
        if (dir < 0 && ((Math.floor(this.currentPage / 100) * 100) + dir * 100) <= 0) {
            this.gotoRelPage(0, dir);
        } else {
            this.gotoRelPage(Math.floor(this.currentPage / 100) * 100 - 1 + (dir * 100), 1);
        }
    }

    private cancelDigitEntry() {
        this.currentDigitEntry = '';
        this.digit.next(this.currentDigitEntry);
    }

    public inputDigit(k: string) {
        if (this.subscription.digitEntryTimeout) {
            SOM.clearSubscriptions(this.subscription.digitEntryTimeout);
        }
        this.subscription.digitEntryTimeout = timer(2000).subscribe(() => {
            this.cancelDigitEntry();
        });
        this.currentDigitEntry += k;
        this.digit.next(this.currentDigitEntry);
        if (this.currentDigitEntry.length >= 3) {
            const wantPage = parseInt(this.currentDigitEntry.slice(0, 3), 10);
            this.currentDigitEntry = '';
            if (this.pageList.indexOf(wantPage) >= 0) {
                this.loadPage(wantPage);
            }
        }
    }

    public isTransparent() {
        return this.transparent;
    }
}
