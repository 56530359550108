import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { TeletextService } from '@kuki/global/features/teletext/teletext.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { ArrisKeys, CommonKeys, GroupKeys } from '@kuki/global/shared/types/controller/keymap';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { numKeyToNumberMap } from '@kuki/global/shared/types/controller/controller';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { hal } from '@kuki/platforms/hal';
import { Channel } from '@kuki/global/shared/types/general';

@Component({
    selector: 'app-teletext',
    templateUrl: './teletext.component.html'
})
export class TeletextComponent implements OnInit, OnDestroy {

    @Input() ident: string = 'teletext';
    @Input() channel: Channel;
    private isactivated: boolean;

    public teletextHtml: SafeHtml;
    public digit: string;

    private subscription: SubscriptionObject = {};

    constructor(
        private teletextService: TeletextService,
        private notificationService: NotificationService,
        private controllerService: ControllerService,
        private domSanitizer: DomSanitizer,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone) {
    }

    ngOnInit() {
        this.activate();
    }

    private activate() {
        if (this.isactivated) {
            this.deactivate();
        }
        if (!this.channel) {
            return;
        }
        this.isactivated = true;
        const ttxident = this.channel.swttxIdent;
        if (!ttxident) {
            this.notificationService.show(this.translateService.instant('NOTIFICATIONS.GENERAL.TTX_IS_NOT_SUPPORTED'));
        }

        this.subscription.teletextHtml = this.teletextService.teletextHtml$.subscribe((teletextHtml) => {
            this.teletextHtml = this.domSanitizer.bypassSecurityTrustHtml(teletextHtml);
            this.changeDetectorRef.detectChanges();
        });

        this.subscription.digit = this.teletextService.digit$.subscribe((digit) => {
            this.digit = digit;
            this.changeDetectorRef.detectChanges();
        });

        this.subscription.transparencyChanged = this.teletextService.transparencyChanged$.subscribe(() => {
            this.changeDetectorRef.detectChanges();
        });

        this.ngZone.runOutsideAngular(() => {
            this.teletextService.init(ttxident);
        });
        this.registerControls();

        this.subscription.teletextStart = this.teletextService.start(this.channel.id).subscribe();
    }

    private registerControls() {
        this.controllerService.registerActionKey(CommonKeys.LEFT, this.ident, () => {
            this.teletextService.gotoPreviousPage();
        });
        this.controllerService.registerActionKey(CommonKeys.RIGHT, this.ident, () => {
            this.teletextService.gotoNextPage();
        });
        this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => {
            this.teletextService.gotoPreviousMag();
        });
        this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => {
            this.teletextService.gotoNextMag();
        });
        this.controllerService.registerActionKey(CommonKeys.GRP_EPG, this.ident, () => {
            this.teletextService.gotoIndexPage();
        });
        this.controllerService.registerActionKey(CommonKeys.GRP_TV, this.ident, () => {
            this.teletextService.setTransparent(!this.teletextService.isTransparent());
        });
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => {
            this.teletextService.setPause(!this.teletextService.paused);
        });
        this.controllerService.registerActionKeys(GroupKeys.GRP_NUMBER, this.ident, (action) => {
            this.teletextService.inputDigit(numKeyToNumberMap[ action.actionKey ]);
        });
        if (hal.platform === 'TV.ARRIS') {
            this.controllerService.propagateActionKey(ArrisKeys.TXT, this.ident);
        }
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private deactivate() {
    }

    public isTransparent() {
        return this.teletextService.isTransparent();
    }

    public onMagazineUp() {
        this.teletextService.gotoNextMag();
    }

    public onMagazineDown() {
        this.teletextService.gotoPreviousMag();
    }

    public onPageUp() {
        this.teletextService.gotoNextPage();
    }

    public onPageDown() {
        this.teletextService.gotoPreviousPage();
    }

    ngOnDestroy() {
        this.unregisterControls();
        this.teletextService.destroy();
        this.isactivated = false;
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription.teletextStop = this.teletextService.stop().subscribe(() => {
            SOM.clearSubscriptions(this.subscription.teletextStop);
        });
    }
}
