import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@kuki/environments/environment';


@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeComponent implements OnInit, OnDestroy {

    public time: number;
    private timeInterval: any;

    public publicVersion = environment.public;

    constructor(
        private ngZone: NgZone,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (!environment.public) {
            this.renderer.addClass(this.elementRef.nativeElement, 'time-dev');
        }
        this.time = Date.now();
        this.ngZone.runOutsideAngular(() => {
            this.timeInterval = setInterval(() => {
                this.time = Date.now();
                this.changeDetectorRef.detectChanges();
            }, 1000);
        });
    }

    ngOnDestroy() {
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
    }
}
