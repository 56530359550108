import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoJSPlayerComponent } from 'app/global/features/media-player/media-player-hals/videojs-player/videojs-player.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        VideoJSPlayerComponent
    ],
    exports: [
        VideoJSPlayerComponent
    ]
})
export class VideoJSPlayerModule {
}
