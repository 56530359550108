import { Injectable } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class InfoPanelService {

    private loaded: Subject<Media> = new Subject<Media>();
    public loaded$: Observable<Media> = this.loaded.asObservable();

    private sizeChanged: Subject<string> = new Subject<string>();
    public sizeChanged$: Observable<string> = this.sizeChanged.asObservable();

    private posChanged: Subject<string> = new Subject<string>();
    public posChanged$: Observable<string> = this.posChanged.asObservable();

    private visibilityChanged: Subject<boolean> = new Subject<boolean>();
    public visibilityChanged$: Observable<boolean> = this.visibilityChanged.asObservable();

    public load(media: Media) {
        this.loaded.next(media);
    }

    public unload() {
        this.loaded.next(null);
    }

    public changeSize(dim = null) {
        this.sizeChanged.next(dim);
    }

    public changePos(pos = null) {
        this.posChanged.next(pos);
    }

    public changeVisibility(visible: boolean) {
        this.visibilityChanged.next(visible);
    }
}
