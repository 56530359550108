<div class="modal-body">
    <div class="modal-title">{{ data.message }}</div>
    <app-buttons-panel *ngIf="actionButtons && actionButtons.length > 0"
                       [type]="'vertical'"
                       [active]="true"
                       [priv]="true"
                       [buttons]="actionButtons"
                       [locked]="locked"
                       [ident]="data.ident+'-buttons'"
                       [infinityScroll]="true"
                       (action)="onAction($event)"></app-buttons-panel>
    <div *ngIf="loaded && (!actionButtons || actionButtons.length === 0)" class="actions-modal-empty">{{ data.emptyMessage }}</div>
</div>
