import { Component, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileTile } from '@kuki/global/shared/types/tile';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { Actions, ImageTypes } from '@kuki/global/shared/types/enum';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-profile-tile',
    templateUrl: './profile-tile.component.html'
})
export class ProfileTileComponent implements OnInit, OnDestroy {
    @Input() tile: ProfileTile;
    @Input() @HostBinding('class.tile-focus') focus: boolean;
    @Input() actions: boolean = true;
    @Input() clickDisabled: boolean;
    @Input() noGrad: boolean;

    @HostBinding('class.tile-active') tileProfileActive: boolean;


    public ImageType = ImageTypes;
    private activeProfile: ProfileTile;
    private subscription: SubscriptionObject = {};

    constructor(
        private coreService: CoreService,
        private profileService: ProfileService,
        private tileService: TileService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    ngOnInit() {
        this.prepareActiveProfile();
        this.subscription.onProfileChanged = this.profileService.onProfileChanged$.subscribe(() => {
            this.prepareActiveProfile();
        });
    }

    private prepareActiveProfile() {
        this.activeProfile = this.profileService.getActiveProfile();
        this.tileProfileActive = this.activeProfile && this.activeProfile.id === this.tile.id;
    }

    public showActions() {
        return this.coreService.isWebPlatform() && this.focus && this.actions && this.showUpdateActions();
    }

    private showUpdateActions() {
        return this.activeProfile?.profileType !== 'kid';
    }

    public onEditProfile($event) {
        this.preventPropagate($event);
        this.tileService.doAction(Actions.EDIT_PROFILE, this.tile).subscribe();
    }

    public onDeleteProfile($event) {
        this.preventPropagate($event);
        this.tileService.doAction(Actions.DELETE_PROFILE, this.tile).subscribe();
    }

    private preventPropagate($event) {
        if ($event && $event.stopPropagation) {
            $event.stopPropagation();
        }
    }

    @HostListener('click', [ '$event' ])
    public onClick(event) {
        if (this.clickDisabled) {
            return;
        }
        const activeProfile = this.profileService.getActiveProfile();
        if (activeProfile && activeProfile.id === this.tile.id) {
            return;
        }
        return this.modalsService.openConfirmModal({
            message: this.translateService.instant('GENERAL.SWITCH_PROFILE_CONFIRM_MESSAGE')
        })
            .then(() => this.profileService.activateProfileProcess(this.tile.id).toPromise())
            .then(() => {
                this.notificationService.show(
                    this.translateService.instant('NOTIFICATIONS.PROFILE.PROFILE_ACTIVATED',
                        { name: this.tile.label }));
            }).catch(() => {
            });
    }

    public ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
