<div class="teletext"
     (swipeup)="onMagazineUp()"
     (swipedown)="onMagazineDown()"
     (swipeleft)="onPageUp()"
     (swiperight)="onPageDown()"
     [ngClass]="{ transparent: isTransparent() }"
     [innerHTML]="teletextHtml"></div>
<div class="teletext-digit">{{ digit }}</div>

<ng-content></ng-content>

