import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { SectionControlService } from '@kuki/tv/shared/modules/section-control/section-control.service';

@Component({
    selector: 'app-section-arrows',
    templateUrl: './section-arrows.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionArrowsComponent implements OnInit, OnDestroy {
    public enabled: boolean = false;
    public sectionArrowUpEnabled: boolean = false;
    public sectionArrowDownEnabled: boolean = false;
    private subscription: SubscriptionObject = {};

    constructor(
        private sectionControlService: SectionControlService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.subscription.onSectionArrowsEnabled = this.sectionControlService.onSectionArrowsEnabled$.subscribe((enabled: boolean) => {
            this.enabled = enabled;
            this.changeDetectorRef.detectChanges();
        });
        this.subscription.onMouseControlUpEnabled = this.sectionControlService.onSectionArrowUpEnabled$.subscribe((enabled) => {
            this.sectionArrowUpEnabled = enabled;
            this.changeDetectorRef.detectChanges();
        });

        this.subscription.onMouseControlDownEnabled = this.sectionControlService.onSectionArrowDownEnabled$.subscribe((enabled) => {
            this.sectionArrowDownEnabled = enabled;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy(): void {
        SOM.clearSubscriptionsObject(this.subscription);
    }

}
