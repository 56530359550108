import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { KeyboardConfig } from '@kuki/global/shared/types/general';
import { CommonKeys, GroupKeys } from '@kuki/global/shared/types/controller/keymap';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { ControllerAction, numKeyToNumberMap } from '@kuki/global/shared/types/controller/controller';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { KeyboardModes } from '@kuki/tv/features/keyboard/keyboard.modes';
import { keyboardMaps } from '@kuki/tv/features/keyboard/keyboard.data';

@Component({
    selector: 'app-keyboard',
    templateUrl: './keyboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyboardComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ident: string = 'keyboard';
    @Input() infinityVerScroll: boolean;
    @Input() config: KeyboardConfig;
    @Input() mode: KeyboardModes;
    @Input() literal: string;
    @Input() maxLength: number = 25;
    @Input() priv: boolean;

    @Output() keyEnter: EventEmitter<string> = new EventEmitter<string>();
    @Output() keyOk: EventEmitter<void> = new EventEmitter<void>();
    @Output() overflow: EventEmitter<number> = new EventEmitter<number>();

    private _defaultConfig = {
        noOk: false
    };

    public keyboard: Array<Array<string>>;
    public keyboardValue: Array<Array<string>>;
    private activeKey: { y: number, x: number } = { x: 0, y: 0 };
    public caps: boolean = false;
    private _okKey: { y: number, x: number };
    private activeHistory: Array<number> = [];
    private active: boolean;

    private _counter = 0;

    private _keyboardSpecificMeta = {
        'normal': {
            'MODE': {
                value: '123',
                onEnter: () => this.changeMode('alt')
            },
            'CAPS': {
                value: '<span class=\'icon icon-arrow-up\'></span>',
                onEnter: () => this.toggleCaps()
            }
        },
        'alt': {
            'MODE': {
                value: 'abc',
                onEnter: () => this.changeMode('normal')
            }
        }
    };

    private keyboardMeta = {
        'BCKSP': {
            value: '<span class=\'icon icon-backspace\'></span>'
        }
    };

    constructor(
        private generalService: GeneralService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private controllerService: ControllerService,
        private componentRegisterService: ComponentRegisterService,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.keyboard = this.getKeyboard();
        this.generateKeyboardValues();
        this.componentRegisterService.registerComponent<KeyboardComponent>(this.ident, this, 'keyboard');
        this.config = this.config || this._defaultConfig;
        if (this.mode) {
            this.setKeyboardModeClass();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('config')) {
            this.config = { ...this._defaultConfig, ...this.config };
        }
        if (changes.hasOwnProperty('mode')) {
            this.setKeyboardModeClass();
        }
    }

    public activate() {
        this.active = true;
        this.registerControls();
        this.changeDetectorRef.detectChanges();
    }

    public deactivate() {
        this.active = false;
        this.unregisterControls();
        this.changeDetectorRef.detectChanges();
    }

    private getKeyboard() {
        if (this.config.noOk) {
            return keyboardMaps[ this.mode ].map(keys => {
                return keys.filter(key => key !== 'OK');
            });
        }
        return keyboardMaps[ this.mode ];
    }

    private generateKeyboardValues() {
        this.keyboardValue = this.getKeyboard().map(row => {
            return row.map(key => {
                return this.getKeyValue(key);
            });
        });
    }


    public get keyboardSpecificMeta() {
        return this._keyboardSpecificMeta[ this.mode ];
    }

    public getKeyValue(key: string) {
        if (this.keyboardSpecificMeta && this.keyboardSpecificMeta[ key ] && this.keyboardSpecificMeta[ key ].value) {
            return this.keyboardSpecificMeta[ key ].value;
        } else if (this.keyboardMeta[ key ] && this.keyboardMeta[ key ].value) {
            return this.keyboardMeta[ key ].value;
        } else {
            if (this.caps) {
                return key.toUpperCase();
            }
            return key;
        }
    }

    public getKeyClass(key: string, y: number, x: number) {
        return {
            [ 'key-' + key ]: true,
            [ 'key-active' ]: this.activeKey.y === y && this.activeKey.x === x && this.active
        };
    }

    public trackBy(index: number) {
        return index;
    }

    public onMouseEnter(y: number, x: number) {
        this.activeKey = { y: y, x: x };
        this.changeDetectorRef.detectChanges();
    }

    public onClick() {
        this.onKeyEnter();
    }

    public onKeyOk() {
        this.keyOk.emit();
    }

    public onKeyBack() {
        this.controllerService.emulatePressByActionKey(CommonKeys.GRP_BACK);
    }

    private changeMode(mode: KeyboardModes) {
        this.mode = mode;
        this._okKey = null;
        this.keyboard = this.getKeyboard();
        this.generateKeyboardValues();
    }

    private toggleCaps() {
        this.caps = !this.caps;
        this.generateKeyboardValues();
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.ident, this.priv);
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => this.onKeyEnter());
        this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => this.onKeyUp());
        this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => this.onKeyUp(), 'pl');
        this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => this.onKeyDown());
        this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => this.onKeyDown(), 'pl');
        this.controllerService.registerActionKey(CommonKeys.LEFT, this.ident, () => this.onKeyLeft());
        this.controllerService.registerActionKey(CommonKeys.LEFT, this.ident, () => this.onKeyLeft(), 'pl');
        this.controllerService.registerActionKey(CommonKeys.RIGHT, this.ident, () => this.onKeyRight());
        this.controllerService.registerActionKey(CommonKeys.RIGHT, this.ident, () => this.onKeyRight(), 'pl');
        this.controllerService.registerActionKeys(GroupKeys.GRP_NUMBER, this.ident, (action) => this.onKeyNumber(action));
        this.controllerService.propagateActionKey(CommonKeys.GRP_BACK, this.ident);
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    public onKeyEnter(key = this.keyboard[ this.activeKey.y ][ this.activeKey.x ]) {
        const lastLiteral = this.literal;
        switch (key) {
            case 'BCKSP':
                this.onKeyBcksp();
                break;
            case 'SPACE':
                this.onKeySpace();
                break;
            case 'MODE':
                if (this.keyboardSpecificMeta && this.keyboardSpecificMeta[ key ]) {
                    this.keyboardSpecificMeta[ key ].onEnter();
                }
                break;
            case 'CAPS':
                if (this.keyboardSpecificMeta && this.keyboardSpecificMeta[ key ]) {
                    this.keyboardSpecificMeta[ key ].onEnter();
                }
                break;
            case 'OK':
                this.onKeyOk();
                break;
            default:
                this.onKey(key);
        }
        if (lastLiteral !== this.literal) {
            this.keyEnter.emit(this.literal);
        }
        this.changeDetectorRef.detectChanges();
    }

    public onKeyBcksp() {
        this.literal = this.literal.slice(0, -1);
    }

    public onKeySpace() {
        if (this.literal.length >= this.maxLength) {
            return;
        }
        this.literal = this.literal + ' ';
    }

    public onKey(value: string) {
        if (this.literal.length >= this.maxLength) {
            return;
        }
        value = this.caps ? value.toUpperCase() : value;
        this.literal = this.literal + value;
    }

    private onKeyNumber(action: ControllerAction) {
        if (this.literal.length >= this.maxLength) {
            return;
        }
        const lastLiteral = this.literal;
        this.literal = this.literal + numKeyToNumberMap[ action.actionKey ];
        if (lastLiteral !== this.literal) {
            this.keyEnter.emit(this.literal);
        }
        this.findOkKey();
        this.activeKey = { ...this.okKey };
        this.changeDetectorRef.detectChanges();
    }

    private onKeyUp() {
        if (this.activeKey.y <= 0) {
            this.storeKeyHistory();
            if (!this.infinityVerScroll) {
                this.overflow.emit(-1);
                return;
            }
            this.activeKey.y = this.keyboard.length - 1;
            this.loadKeyHistory();
        } else {
            this.storeKeyHistory();
            this.activeKey.y--;
            this.loadKeyHistory();
        }
        if (!this.keyboard[ this.activeKey.y ][ this.activeKey.x ]) {
            this.activeKey.x = this.keyboard[ this.activeKey.y ].length - 1;
        }
        this.changeDetectorRef.detectChanges();
    }

    private onKeyDown() {
        if (this.activeKey.y >= this.keyboard.length - 1) {
            this.storeKeyHistory();
            if (!this.infinityVerScroll) {
                this.overflow.emit(1);
                return;
            }
            this.activeKey.y = 0;
            this.loadKeyHistory();
        } else if (this.isOkActive()) {
            this.storeKeyHistory();
            if (!this.infinityVerScroll) {
                this.overflow.emit(1);
                return;
            }
            this.activeKey.y = 0;
            this.activeKey.x = this.keyboard[ this.activeKey.y ].length - 1;
        } else {
            this.storeKeyHistory();
            this.activeKey.y++;
            this.loadKeyHistory();
        }
        if (!this.keyboard[ this.activeKey.y ][ this.activeKey.x ]) {
            this.activeKey.x = this.keyboard[ this.activeKey.y ].length - 1;
        }
        this.changeDetectorRef.detectChanges();
    }

    private onKeyLeft() {
        if (this.activeKey.x <= 0) {
            const prevRow = this.keyboard[ this.activeKey.y - 1 ];
            if (this.mode !== 'pin') {
                let OKIndex = -1;
                if (prevRow) {
                    OKIndex = prevRow.findIndex(item => item === 'OK');
                    if (OKIndex >= 0) {
                        this.activeKey.x = OKIndex;
                        this.activeKey.y = this.activeKey.y - 1;
                    }
                }
                if (OKIndex === -1) {
                    this.activeKey.x = this.keyboard[ this.activeKey.y ].length - 1;
                }
            } else {
                this.activeKey.x = this.keyboard[ this.activeKey.y ].length - 1;
            }
        } else {
            this.activeKey.x--;
            this.clearKeyHistory();
            this.storeKeyHistory();
        }
        this.changeDetectorRef.detectChanges();
    }

    private onKeyRight() {
        if (this.activeKey.x >= this.keyboard[ this.activeKey.y ].length - 1) {
            const prevRow = this.keyboard[ this.activeKey.y - 1 ];
            if (this.mode !== 'pin') {
                let OKIndex = -1;
                if (prevRow) {
                    OKIndex = prevRow.findIndex(item => item === 'OK');
                    if (OKIndex >= 0) {
                        this.activeKey.x = OKIndex;
                        this.activeKey.y = this.activeKey.y - 1;
                    }
                }
                if (OKIndex === -1) {
                    this.activeKey.x = 0;
                }
            } else {
                this.activeKey.x = 0;
            }
        } else {
            this.activeKey.x++;
            this.clearKeyHistory();
            this.storeKeyHistory();
        }
        this.changeDetectorRef.detectChanges();
    }

    private findOkKey() {
        let okKey = null;
        for (let i = 0; i < this.keyboard.length; i++) {
            if (okKey) {
                break;
            }
            for (let j = 0; j < this.keyboard[ i ].length; j++) {
                if (this.keyboard[ i ][ j ] === 'OK') {
                    okKey = { y: i, x: j };
                    break;
                }
            }
        }
        return okKey;
    }

    private get okKey() {
        this._okKey = this._okKey || this.findOkKey() || { y: 0, x: 0 };
        return this._okKey;
    }

    private storeKeyHistory(y: number = this.activeKey.y, x: number = this.activeKey.x) {
        this.activeHistory[ y ] = x;
    }

    private loadKeyHistory(y: number = this.activeKey.y) {
        const history = this.activeHistory[ y ];
        if (history) {
            this.activeKey.x = history;
        }
    }

    private clearKeyHistory() {
        this.activeHistory = [];
    }

    private isOkActive() {
        return this.keyboard[ this.activeKey.y ] [ this.activeKey.x ] === 'OK';
    }

    private setKeyboardModeClass() {
        this.renderer.addClass(this.elementRef.nativeElement, 'keyboard-' + this.mode);
    }

    ngOnDestroy() {
        this.unregisterControls();
        this.componentRegisterService.unregisterComponent(this.ident);
    }
}
