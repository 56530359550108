import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

type Star = 'half' | 'full' | 'empty';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html'
})
export class RatingComponent implements OnChanges {
    @Input() small: boolean;
    @Input() rating: number;

    stars: Array<Star>;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('rating')) {
            if (!this.small) {
                this.calculateStars();
            }
        }
    }

    calculateStars() {
        const starsCount = Math.floor(this.rating / 20);
        this.stars = Array(starsCount).fill('full');
        if (this.rating % 20 >= 10 || this.rating < 10) {
            this.stars.push('half');
        }
        this.stars = [ ...this.stars, ...Array(5 - this.stars.length).fill('empty') ];

    }

    starIcon(star: Star) {
        switch (star) {
            case 'full':
                return 'icon-star';
            case 'half':
                return 'icon-star-half';
            case 'empty':
                return 'icon-star-white';
        }
    }
}
