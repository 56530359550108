import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { FormComponent } from '@kuki/tv/features/form/form.component';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { environment } from '@kuki/environments/environment';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-pin-modal',
    templateUrl: './pin-modal.component.html'
})
export class PinModalComponent implements OnInit, AfterViewInit, OnDestroy {

    private formComponent: FormComponent;
    private subscription: SubscriptionObject = {};

    constructor(
        private authService: AuthService,
        private ngbActiveModal: NgbActiveModal,
        private notificationService: NotificationService,
        private profileService: ProfileService,
        private controllerService: ControllerService,
        private componentRegisterService: ComponentRegisterService,
        private translateService: TranslateService) {
    }

    private defaults = {
        ident: 'pin-modal',
        message: this.translateService.instant('MODAL.PIN.MESSAGE')
    };

    public data: {
        message: string;
        type: 'device' | 'profile';
        profileId: number;
        ident: string;
    };

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.registerControls();
    }

    ngAfterViewInit() {
        this.formComponent = this.componentRegisterService.getComponent<FormComponent>('pin-modal-form');
        this.formComponent.activate();
    }

    public onSubmit(pin: string) {
        const checkApi$ = this.data && this.data.type === 'profile' ?
            this.profileService.checkProfilePin(this.data.profileId, pin) :
            this.authService.checkPassword(pin);
        this.subscription.checkPassword = checkApi$.subscribe((res: any) => {
            if ((res && res.state === 'OK') || (!environment.production && pin === 'test')) {
                this.ngbActiveModal.close(pin);
            }
        }, () => {
            // just for fast testing case
            if (!environment.production && pin === 'test') {
                this.ngbActiveModal.close(pin);
            } else {
                this.notificationService.show(this.translateService.instant('MODAL.PIN.FAIL_MESSAGE'));
            }
        });
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.data.ident, true);
        this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.data.ident, () => this.ngbActiveModal.dismiss());
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.data.ident);
    }

    ngOnDestroy() {
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
        this.notificationService.clear();
    }

}
