import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActionButton } from '@kuki/global/shared/types/button';
import { TileRow } from '@kuki/global/shared/types/general';
import { TranslateService } from '@ngx-translate/core';
import { MediaTile, Tile, VodTile } from '@kuki/global/shared/types/tile';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { of } from 'rxjs';
import { Actions, Tags, TileTypes } from '@kuki/global/shared/types/enum';
import { AuthService } from '@kuki/global/sections/auth/auth.service';

@Component({
    selector: 'app-actions-modal',
    templateUrl: './actions-modal.component.html'
})
export class ActionsModalComponent implements OnInit, OnDestroy {

    private defaults = {
        ident: 'actions-modal',
        message: this.translateService.instant('MODAL.ACTIONS.MESSAGE'),
        closeWithoutButtons: true,
        emptyMessage: this.translateService.instant('MODAL.ACTIONS.EMPTY')
    };

    public data: {
        ident: string;
        type: string;
        tile?: Tile;
        tileRow?: TileRow;
        message?: string;
        closeWithoutButtons?: boolean;
        emptyMessage?: string;
    };
    private subscription: SubscriptionObject = {};

    private buttonsTemplates: { [ key: string ]: ActionButton } = {
        play: {
            label: this.translateService.instant('GENERAL.ACTION.PLAY'),
            action: Actions.PLAY,
            icon: 'icon-play-new',
        },
        startOver: {
            label: this.translateService.instant('GENERAL.ACTION.START_OVER'),
            action: Actions.PLAY_START_OVER,
            icon: 'icon-start-over-new'
        },
        showMediaDetail: {
            label: this.translateService.instant('GENERAL.ACTION.SHOW_MEDIA_DETAIL'),
            action: Actions.SHOW_MEDIA_DETAIL,
            icon: 'icon-detail',
        },
        record: {
            label: this.translateService.instant('GENERAL.ACTION.RECORD'),
            action: Actions.RECORD,
            class: 'btn-record',
            icon: 'icon-record',
        },
        notify: {
            label: this.translateService.instant('GENERAL.ACTION.NOTIFY'),
            action: Actions.NOTIFY,
            class: 'btn-notify',
            icon: 'icon-notify',
        },
        favourite: {
            label: this.translateService.instant('GENERAL.ACTION.FAVOURITE'),
            action: Actions.FAVOURITE,
            class: 'btn-favourite',
            icon: 'icon-favourite',
        },
        buy: {
            label: this.translateService.instant('GENERAL.ACTION.BUY_VOD'),
            action: Actions.BUY_VOD,
            icon: 'icon-buy'
        },
        hideTilesRow: {
            label: this.translateService.instant('GENERAL.ACTION.HIDE_TILES_ROW'),
            action: Actions.HIDE_TILES_ROW,
            icon: 'icon-hide'
        },
        showTilesRow: {
            label: this.translateService.instant('GENERAL.ACTION.SHOW_TILES_ROW'),
            action: Actions.SHOW_TILES_ROW,
            icon: 'icon-show'
        },
        editProfile: {
            label: this.translateService.instant('GENERAL.ACTION.EDIT_PROFILE'),
            action: Actions.EDIT_PROFILE,
            icon: 'icon-pen',
        },
        deleteProfile: {
            label: this.translateService.instant('GENERAL.ACTION.DELETE_PROFILE'),
            action: Actions.DELETE_PROFILE,
            icon: 'icon-trash'
        },
        deactivateProfile: {
            label: this.translateService.instant('GENERAL.ACTION.DEACTIVATE_PROFILE'),
            action: Actions.DEACTIVATE_PROFILE,
            icon: 'icon-remove-circle'
        },
        openTeleport: {
            label: this.translateService.instant('GENERAL.ACTION.OPEN_TELEPORT_TO'),
            action: Actions.OPEN_TELEPORT_TO,
            icon: 'icon-teleport'
        },
        teleportTo: {
            action: Actions.TELEPORT_TO
        }
    };
    public actionButtons: Array<ActionButton>;
    public locked: boolean;
    public loaded: boolean = false;

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private controllerService: ControllerService,
        private tileService: TileService,
        private profileService: ProfileService,
        private coreService: CoreService,
        private authService: AuthService,
        private deviceService: DeviceService,
        private ngZone: NgZone) {
    }

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.registerControls();
        this.subscription.initButtons = this.initButtons().subscribe(() => {
            this.loaded = true;
            if (this.data.closeWithoutButtons && this.actionButtons.length === 0) {
                this.ngbActiveModal.dismiss();
                return;
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    private initButtons() {
        this.actionButtons = [];
        switch (this.data.type) {
            case 'dashboard-management':
                if (this.data.tileRow.hidden) {
                    this.actionButtons.push({ ...this.buttonsTemplates.showTilesRow });
                } else {
                    this.actionButtons.push({ ...this.buttonsTemplates.hideTilesRow });
                }
                break;
            case 'teleport-to':
                const devices = this.deviceService.getDevicesForTeleport(this.authService.serial);
                this.actionButtons = devices.map(device => (
                    {
                        ...this.buttonsTemplates.teleportTo,
                        label: device.alias,
                        id: device.id,
                        icon: this.tileService.getDeviceIcon(device)
                    }
                ));
                break;
            default:
                switch (this.data.tile.type) {
                    case TileTypes.MEDIA:
                        const mediaTile = this.data.tile as MediaTile;
                        if (!this.coreService.isProfileActive() || this.coreService.isProfileTypeActive('classic', 'senior')) {
                            if (this.tileService.showPlayAction(mediaTile)) {
                                this.actionButtons.push(this.buttonsTemplates.play);
                            }
                            if (this.tileService.showStartOver(mediaTile)) {
                                this.actionButtons.push(this.buttonsTemplates.startOver);
                            }
                            if (this.tileService.showBuyAction(mediaTile)) {
                                const vodTile = this.data.tile as VodTile;
                                if (!vodTile.own.subscription) {
                                    this.actionButtons.push({
                                        ...this.buttonsTemplates.buy,
                                        label: vodTile.raw.price > 0 ?
                                            this.translateService.instant('GENERAL.ACTION.BUY_VOD_FOR', { price: vodTile.price }) :
                                            this.translateService.instant('GENERAL.ACTION.BUY_VOD_FREE')
                                    });
                                } else {
                                    this.actionButtons.push({
                                        ...this.buttonsTemplates.buy,
                                        label: this.translateService.instant('GENERAL.ACTION.PLAY')
                                    });
                                }
                            }

                            if (this.tileService.showMediaDetail(mediaTile)) {
                                this.actionButtons.push({ ...this.buttonsTemplates.showMediaDetail });
                            }

                            if (this.tileService.showNotifyAction(mediaTile)) {
                                this.actionButtons.push({
                                    ...this.buttonsTemplates.notify,
                                    label: this.tileService.tagExists(mediaTile, Tags.NOTIFY) ?
                                        this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.notify.label
                                });
                            }
                            if (this.tileService.showFavouriteAction(mediaTile)) {
                                this.actionButtons.push({
                                    ...this.buttonsTemplates.favourite,
                                    label: this.tileService.tagExists(mediaTile, Tags.FAVOURITE) ?
                                        this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.favourite.label
                                });
                            }
                            if (this.tileService.showRecordAction(mediaTile)) {
                                this.actionButtons.push({
                                    ...this.buttonsTemplates.record,
                                    label: this.tileService.tagExists(mediaTile, Tags.REC) ?
                                        this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.record.label
                                });
                            }
                            if (this.tileService.showTeleportAction(mediaTile)) {
                                this.actionButtons.push({
                                    ...this.buttonsTemplates.openTeleport
                                });
                            }
                        }
                        break;
                    case TileTypes.PROFILE:
                        const activeProfile = this.profileService.getActiveProfile();
                        if (!activeProfile || activeProfile.profileType !== 'kid') {
                            this.actionButtons.push({ ...this.buttonsTemplates.editProfile });
                            this.actionButtons.push({ ...this.buttonsTemplates.deleteProfile });
                        }
                        if (activeProfile && activeProfile.id === this.data.tile.id) {
                            this.actionButtons.push({ ...this.buttonsTemplates.deactivateProfile });
                        }
                        break;
                }
        }
        return of(undefined);
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.data.ident, true);
        this.controllerService.registerActionKeys([ CommonKeys.GRP_BACK, CommonKeys.GRP_INFO, CommonKeys.GRP_TV ], this.data.ident, () => {
            this.ngbActiveModal.dismiss();
        });
        this.controllerService.propagateActionKeys([ CommonKeys.GRP_REC ], this.data.ident);
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.data.ident);
    }

    public onAction(activeButton: ActionButton) {
        SOM.clearSubscriptions(this.subscription.onButtonAction);
        this.ngZone.run(() => {
            if (this.data.type === 'dashboard-management') {
                this.ngbActiveModal.close(activeButton.action);
            } else {
                this.locked = true;
                if (activeButton.action === Actions.OPEN_TELEPORT_TO) {
                    this.ngbActiveModal.close(activeButton.action);
                }
                this.subscription.onButtonAction = this.tileService.doAction(activeButton.action, this.data.tile, activeButton)
                    .subscribe(() => {
                        this.locked = false;
                        this.ngbActiveModal.close(activeButton.action);
                    }, () => {
                        this.locked = false;
                        this.ngbActiveModal.close(activeButton.action);
                    });
            }
        });
    }

    ngOnDestroy() {
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
