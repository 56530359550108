import { Injectable } from '@angular/core';
import { HalFeature } from '@kuki/global/shared/types/general';
import { BootModeTypes } from '@kuki/global/shared/types/enum';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { Observable, ReplaySubject } from 'rxjs';

declare var webOS;

@Injectable()
export class WebosPlatformHalService implements PlatformHal {

    private deviceReady: ReplaySubject<void> = new ReplaySubject<void>(1);
    public deviceReady$: Observable<void> = this.deviceReady.asObservable();

    private readonly serialKey = 'kuki-sn';

    private serial: string;
    private deviceInfo: {
        modelName: string,
        firmwareVersion: string,
        sdkVersion: string,
        UHD: string
    };
    private features: HalFeature;

    private readonly loginStyle = 'k2';

    public init() {
        this.initLoginStyle();
        Promise.all([ this.fetchSerial(), this.fetchDeviceInfo() ]).then(() => {
            this.deviceReady.next();
            this.deviceReady.complete();
        }).catch((error) => {
            this.deviceReady.error(error);
        });
    }

    public getSerial(): string {
        const loginStyle = this.getLoginStyle();
        switch (loginStyle) {
            case 'deviceId':
                return this.serial;
            case 'k2':
                let serial = localStorage.getItem(this.serialKey);
                if (!serial) {
                    serial = this.generateSerial();
                }
                return serial;
            // just for sure
            default:
                return this.serial;
        }
    }

    public getDeviceModel(): string {
        return `${ this.deviceInfo.modelName || '' }`;
    }

    public getProductName(): string {
        return `${ this.deviceInfo.modelName || '' }`;
    }

    public getMac(): string {
        // missing in webos connection manager api
        return '00:00:00:00:00:00';
    }

    public getVersionFw(): string {
        return this.deviceInfo.sdkVersion || '1.0';
    }

    public getBootMode(): BootModeTypes {
        return BootModeTypes.UNKNOWN;
    }

    public getClaimedDeviceId(): string {
        return this.serial;
    }

    private fetchSerial() {
        return new Promise((resolve, reject) => {
            webOS.service.request('luna://com.webos.service.sm', {
                method: 'deviceid/getIDs',
                parameters: {
                    'idType': [ 'LGUDID' ]
                },
                onSuccess: (inResponse) => {
                    console.log('deviceid/getIDs - serial', inResponse[ 'idList' ][ 0 ][ 'idValue' ]);
                    this.serial = inResponse[ 'idList' ][ 0 ][ 'idValue' ];
                    resolve();
                },
                onFailure: (inError) => {
                    console.log('Failed to get system ID information');
                    console.log('[' + inError.errorCode + ']: ' + inError.errorText);
                    reject(inError);
                    // To-Do something
                    return;
                }
            });
        });
    }

    private fetchDeviceInfo() {
        return new Promise((resolve, reject) => {
            webOS.service.request('luna://com.webos.service.tv.systemproperty', {
                method: 'getSystemInfo',
                parameters: {
                    'keys': [ 'modelName', 'firmwareVersion', 'sdkVersion', 'UHD' ]
                },
                onComplete: (inResponse) => {
                    console.log('getSystemInfo', inResponse);
                    const isSucceeded = inResponse.returnValue;
                    if (isSucceeded) {
                        this.deviceInfo = {
                            modelName: inResponse.modelName,
                            firmwareVersion: inResponse.firmwareVersion,
                            sdkVersion: inResponse.sdkVersion,
                            UHD: inResponse.UHD
                        };
                        resolve();
                    } else {
                        console.log('Failed to get system information');
                        reject('WEBOS - Failed to get system information');
                        return;
                    }
                }
            });
        });
    }

    public checkConnection(): Promise<string> {
        return new Promise((resolve, reject) => {
            webOS.service.request('luna://com.palm.connectionmanager', {
                method: 'getStatus',
                onSuccess: function (inResponse) {
                    // console.log('Result: ' + JSON.stringify(inResponse));
                    if (inResponse.isInternetConnectionAvailable) {
                        if (inResponse.wired.state === 'disconnected' && inResponse.wifi.state === 'connected') {
                            resolve('wireless');
                            return;
                        }
                        resolve('ok');
                    } else {
                        resolve('fail');
                    }
                },
                onFailure: function (inError) {
                    console.log('Failed to get network state');
                    console.log('[' + inError.errorCode + ']: ' + inError.errorText);
                    reject();
                    return;
                }
            });
        });
    }

    public restart() {
        window.location.assign('index.html');
    }

    public reboot() {
        window.location.assign('index.html');
    }

    public exitApp() {
        webOS.platformBack();
    }

    public getFeatures(): HalFeature {
        if (!this.features) {
            this.features = {
                hevc: true,
                wv: this.isWVSupported()
            };
        }
        return this.features;
    }

    private initLoginStyle() {
        const loginStyle = this.getLoginStyle();
        if (!loginStyle) {
            localStorage.setItem('loginStyle', this.loginStyle);
        }
    }

    private getLoginStyle() {
        return localStorage.getItem('loginStyle');
    }

    private generateSerial(): string {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let serial = 'kuki2.0_';
        for (let i = 0; i < 56; i++) {
            serial += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        localStorage.setItem(this.serialKey, serial);
        return serial;
    }

    private isWVSupported(): boolean {
        // is greater then 4.x
        return +(this.getVersionFw().split('.')[ 0 ]) >= 4;
    }
}
