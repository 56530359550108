import { NgModule } from '@angular/core';
import { AppRoutingModule } from '@kuki/tv/app-routing.module';
import { AppSharedModule } from '@kuki/root/app/app.shared.module';
import { AppComponent } from '@kuki/tv/app.component';
import { TimeModule } from '@kuki/tv/features/time/time.module';
import { ModalsModule } from '@kuki/tv/modals/modals.module';
import { VolumeModule } from '@kuki/global/features/volume/volume.module';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { EpgModule } from '@kuki/tv/features/epg/epg.module';
import { InfoPanelModule } from '@kuki/tv/features/info-panel/info-panel.module';
import { MouseControlModule } from '@kuki/tv/features/mouse-control/mouse-control.module';
import { SectionArrowsModule } from '@kuki/tv/features/section-arrows/section-arrows.module';
import { ProfileIconModule } from '@kuki/global/features/profile-icon/profile-icon.module';
import { VolumeV2Module } from '@kuki/global/features/volume-v2/volume-v2.module';
import { ScreenSaverModule } from '@kuki/tv/features/screen-saver/screen-saver.module';

@NgModule({
    imports: [
        AppRoutingModule,
        AppSharedModule,
        ModalsModule,
        TimeModule,
        DirectivesModule,
        InfoPanelModule.forRoot(),
        EpgModule.forRoot(),
        MouseControlModule,
        SectionArrowsModule,
        VolumeModule,
        VolumeV2Module,
        ProfileIconModule,
        ScreenSaverModule
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
