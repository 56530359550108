import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnTileComponent } from './btn-tile/btn-tile.component';
import { PersonTileComponent } from './person-tile/person-tile.component';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';
import { TranslateModule } from '@ngx-translate/core';
import { MediaTileComponent } from './media-tile/media-tile.component';
import { ProfileTileComponent } from './profile-tile/profile-tile.component';
import { ImgTileComponent } from './img-tile/img-tile.component';
import { DeviceTeleportTileComponent } from './device-teleport-tile/device-teleport-tile.component';
import { ProfileIconTileComponent } from '@kuki/web/features/tile/profile-icon-tile/profile-icon-tile.component';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { ChannelTileComponent } from './channel-tile/channel-tile.component';
import { PreviewImageLinkFactoryModule } from '@kuki/global/shared/modules/preview-image-link-factory/preview-image-link-factory.module';

@NgModule({
    imports: [
        CommonModule,
        AutoScrollContainerModule,
        ImageLinkFactoryModule,
        TranslateModule,
        DirectivesModule,
        PreviewImageLinkFactoryModule
    ],
    declarations: [
        BtnTileComponent,
        PersonTileComponent,
        MediaTileComponent,
        ProfileTileComponent,
        ImgTileComponent,
        DeviceTeleportTileComponent,
        ProfileIconTileComponent,
        ChannelTileComponent
    ],
    exports: [
        BtnTileComponent,
        PersonTileComponent,
        MediaTileComponent,
        ProfileTileComponent,
        ImgTileComponent,
        DeviceTeleportTileComponent,
        ProfileIconTileComponent,
        ChannelTileComponent
    ]
})
export class TileModule {
}
