<!-- Players -->
<app-shaka-player class="web-player" *ngIf="mediaPlayerHal === 'SHAKA'"></app-shaka-player>
<app-shaka-player-embedded *ngIf="mediaPlayerHal === 'SHAKA_EMBEDDED'"></app-shaka-player-embedded>
<app-exo-player *ngIf="mediaPlayerHal === 'EXO'"></app-exo-player>
<app-tizen-player *ngIf="mediaPlayerHal === 'TIZEN'" [cssClass]="cssClass"></app-tizen-player>
<app-kreatv-player *ngIf="mediaPlayerHal === 'KREATV'" [cssClass]="cssClass"></app-kreatv-player>

<ng-container *ngIf="!hasNativeBuffer() && buffering">
    <div *appPlatform="'TV.ARRIS'; not: true" class="shaka-spinner-container">
        <div class="shaka-spinner">
            <svg class="shaka-spinner-svg" viewBox="0 0 30 30">
                <circle class="shaka-spinner-path"
                        cx="15"
                        cy="15"
                        r="14.5"
                        fill="none"
                        stroke-width="1"
                        stroke-miterlimit="10"></circle>
            </svg>
        </div>
    </div>
    <img *appPlatform="'TV.ARRIS'" class="spinner-gif" src="{{ getDeployUrl() }}assets-out/spinner.gif"/>
</ng-container>

<app-osd *ngIf="osdOpened && tile"
         [tile]="tile"
         [disabled]="isOsdDisabled()"
         (toggleTeletext)="toggleTeletext()"
         (contextMenuToggled)="onContextMenuToggled($event)"
         (seek)="onSeek($event)"
         (playPauseToggle)="onPlayPauseToggle()"></app-osd>

<app-teletext *ngIf="teletextOpened" [ident]="teletextIdent" [channel]="getChannel()">
    <span *ngIf="showTeletextCloseIcon()" class="teletext-close icon-times" (click)="closeTeletext()"></span>
</app-teletext>

<div *ngIf="!!lockedReason" class="locked-player">
    <div class="locked-content" (click)="onOpenPasswordModal()">
        <ng-container *ngIf="lockedReason === 'PARENTAL'">
            <span class="icon icon-locked"></span>
            {{ 'GENERAL.PLAYER.LOCKED_REASON_PARENTAL' | translate }}
        </ng-container>
        <ng-container *ngIf="lockedReason === 'BROADCAST'">
            {{ 'GENERAL.PLAYER.LOCKED_REASON_BROARDCAST' | translate }}
        </ng-container>
        <ng-container *ngIf="lockedReason === 'MISSING_CHANNEL'">
            {{ 'GENERAL.PLAYER.LOCKED_REASON_MISSING_CHANNEL' | translate }}
        </ng-container>
    </div>
</div>
