import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { delay, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';

@Component({
    selector: 'app-shaka-player-embedded',
    template: `
        <div id="subtitleContainer">
            <div id="subtitleElement"></div>
        </div>
    `
})
export class ShakaPlayerEmbeddedComponent implements OnInit, OnDestroy {

    @Input() hideOn: string = 'init';

    constructor(
        @Inject('MediaPlayerHalService') private mediaplayerHalService: MediaPlayerHalInterface,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        const obs = this.hideOn === 'play' ? this.mediaplayerHalService.videoStarted$.pipe(take(1), delay(100)) : of(null);
        obs.subscribe(() => {
            this.renderer.addClass(document.body, 'player-open');
        });
        this.mediaplayerHalService.init();
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'player-open');
    }

}
