export const mediaPlayerConfig = {
    classic: {
        width: 61,
        height: 56,
        left: 39,
    },
    senior: {
        width: 45,
        height: 41,
        left: 56
    },
    kid: {
        width: 53,
        height: 48,
        left: 48
    }
};
