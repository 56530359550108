import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { TimeComponent } from '@kuki/tv/features/time/time.component';

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [ TimeComponent ],
    exports: [
        TimeComponent
    ]
})
export class TimeModule {
}
