import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit, OnDestroy {

    private defaults = {
        ident: 'confirm-modal',
        message: this.translateService.instant('MODAL.CONFIRM.DEFAULT_MESSAGE'),
        btn1: this.translateService.instant('MODAL.CONFIRM.DEFAULT_BTN_1'),
        btn2: this.translateService.instant('MODAL.CONFIRM.DEFAULT_BTN_2'),
        activeBtn: 1,
        confirmBtn: 1,
        closeOnAnyBtn: false,
        timeout: 0
    };
    public data: {
        ident?: string;
        message?: string;
        btn1?: string;
        btn2?: string;
        activeBtn?: number;
        confirmBtn?: number;
        timeout?: number;
        closeOnAnyBtn?: boolean;
    };

    public activeBtn: number;
    public activeChanged: boolean = false;
    public timeoutTimer: number;
    private subscription: SubscriptionObject = {};

    constructor(
        private controllerService: ControllerService,
        private translateService: TranslateService,
        private ngbActiveModal: NgbActiveModal,
        private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.data = { ...this.defaults, ...this.data };
        this.activeBtn = this.data.activeBtn;
        this.registerControls();
        if (this.data.timeout) {
            this.subscription.timeoutTimer = timer(0, 1000).pipe(
                map((seconds) => {
                    return Math.ceil((this.data.timeout / 1000) - seconds);
                }),
                tap((remain) => {
                    if (remain === 0) {
                        this.onOK();
                    }
                })
            ).subscribe((time) => {
                this.timeoutTimer = time;
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    public isButtonActive(btn: number) {
        return this.activeBtn === btn;
    }

    private registerControls() {
        this.controllerService.registerKeyStackLevel(this.data.ident, true);
        this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.data.ident, () => {
            this.ngbActiveModal.dismiss();
        });
        this.controllerService.registerActionKeys([ CommonKeys.LEFT, CommonKeys.RIGHT ], this.data.ident, () => {
            this.onToggle();
        });
        this.controllerService.registerActionKey(CommonKeys.OK, this.data.ident, () => {
            this.onOK();
        });
    }

    private onToggle() {
        this.activeBtn = this.activeBtn === 1 ? 2 : 1;
        this.activeChanged = true;
        this.changeDetectorRef.detectChanges();
    }

    private onOK() {
        if (this.data.closeOnAnyBtn || this.activeBtn === this.data.confirmBtn) {
            this.ngbActiveModal.close(this.activeBtn === this.data.confirmBtn);
        } else {
            this.ngbActiveModal.dismiss();
        }
    }

    public onMouseEnter(number: number) {
        this.activeBtn = number;
        this.changeDetectorRef.detectChanges();
    }

    public onClick(number: number) {
        this.activeBtn = number;
        this.onOK();
    }

    ngOnDestroy() {
        this.controllerService.unregisterStackLevel(this.data.ident);
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
