import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpgComponent } from './epg.component';
import { EpgService } from '@kuki/tv/features/epg/epg.service';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';
import { PipesModule } from '@kuki/global/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [
        EpgComponent
    ],
    imports: [
        CommonModule,
        ImageLinkFactoryModule,
        AutoScrollContainerModule,
        PipesModule,
        TranslateModule
    ],
    exports: [
        EpgComponent
    ],
    providers: []
})
export class EpgModule {
    static forRoot(): ModuleWithProviders<EpgModule> {
        return {
            ngModule: EpgModule,
            providers: [
                EpgService
            ]
        };
    }
}
