import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ChannelSwitcherService } from '@kuki/global/features/channel-switcher/channel-switcher.service';
import { MediaPlayerV2Service } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.service';
import { OsdComponent } from '@kuki/global/features/osd/osd.component';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { ConnectionCheckService } from '@kuki/global/shared/services/connection-check.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { LoggingService } from '@kuki/global/shared/services/logging.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ParentalControlService } from '@kuki/global/shared/services/parental-control.service';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { AndroidTVKeys, ArrisKeys, CommonKeys, GroupKeys, TizenKeys, WebKeys } from '@kuki/global/shared/types/controller/keymap';
import { LogEvents, MediaTypes } from '@kuki/global/shared/types/enum';
import { Errors } from '@kuki/global/shared/types/enum/errors';
import { MediaPlayerTypes } from '@kuki/global/shared/types/media-player';
import { MediaPlayerRestrictions } from '@kuki/global/shared/types/media-player/media-player-restrictions';
import { SettingsParsed } from '@kuki/global/shared/types/settings';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { deployUrl } from '@kuki/root/deploy-url';
import { TranslateService } from '@ngx-translate/core';
import { from, fromEvent, merge, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, skip, skipUntil, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-media-player-v2',
    templateUrl: './media-player-v2.component.html',
    providers: [ MediaPlayerV2Service ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPlayerV2Component implements OnInit, OnDestroy {

    @Input() ident: string = 'media-player';
    @Input() enableControl: boolean = true;
    @Input() enableOsd: boolean = true;
    @Input() cssClass: string;
    @ViewChild(OsdComponent, { static: false }) osdComponent: OsdComponent;

    @Output() osdToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    private readonly CLOSE_DELAY = 3000;
    private readonly CLOSE_DUMB_DELAY = 60000;

    public osdOpened: boolean = false;
    public tile: MediaTile;
    public mediaPlayerHal: string;
    public buffering: boolean;
    public teletextOpened: boolean;
    public teletextIdent: string = 'teletext';
    public lockedReason: MediaPlayerRestrictions;
    private closeTimeout: any;
    private closeDumbTimeout: any;
    private settings: SettingsParsed;
    private chromecastConnected: boolean;
    private airplayConnected: boolean;
    private subscription: SubscriptionObject = {};
    private backgroundPlay: boolean;
    debugParams: any;

    constructor(
        private coreService: CoreService,
        private channelSwitcherService: ChannelSwitcherService,
        public mediaPlayerV2Service: MediaPlayerV2Service,
        private componentRegisterService: ComponentRegisterService,
        private generalService: GeneralService,
        private tileService: TileService,
        private controllerService: ControllerService,
        private settingsService: SettingsService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private parentalControlService: ParentalControlService,
        private powerControlService: PowerControlService,
        private profileService: ProfileService,
        private connectionCheckService: ConnectionCheckService,
        private loggingService: LoggingService,
        private translateService: TranslateService,
        private elementRef: ElementRef,
        private ngZone: NgZone,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    ngOnInit(): void {
        (window as any).mps = this.mediaPlayerV2Service;
        this.backgroundPlay = this.settingsService.getParsedSettingsValue<boolean>('backgroundPlay');
        this.componentRegisterService.registerComponent<MediaPlayerV2Component>(this.ident, this, 'media-player');
        if (this.generalService.isTizen2015()) {
            this.mediaPlayerHal = 'TIZEN';
        } else {
            this.mediaPlayerHal = hal.mediaPlayer.mediaPlayerHal;
        }
        this.watchPlayer();
        this.watchTile();
        this.watchBuffering();
        this.watchChannelSwitching();
        this.watchLock();
        this.watchPowerControl();
        this.watchConnection();
        this.watchChromecast();
        this.watchAirplay();
        this.watchMouse();
        this.watchFullscreen();
        this.settings = this.settingsService.getParsedSettings();
        if (this.enableControl) {
            this.registerControls();
        }
    }

    public hasNativeBuffer() {
        return this.mediaPlayerV2Service.hasNativeBuffer();
    }

    public getDeployUrl() {
        return deployUrl || '';
    }

    public getChannel() {
        return this.mediaPlayerV2Service.getChannel();
    }

    public isOsdDisabled() {
        return !this.enableOsd || this.teletextOpened;
    }

    public showTeletextCloseIcon() {
        return this.coreService.isMobilePlatform() || this.coreService.isWebPlatform();
    }

    private onPause() {
        if (this.mediaPlayerV2Service.isPaused()) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.playerAction);
        this.subscription.playerAction = this.mediaPlayerV2Service.pause().subscribe();
    }

    private onPlay() {
        if (this.mediaPlayerV2Service.isPaused()) {
            SOM.clearSubscriptions(this.subscription.playerAction);
            this.subscription.playerAction = this.mediaPlayerV2Service.unpause().subscribe();
        } else if (this.mediaPlayerV2Service.isStopped()) {
            SOM.clearSubscriptions(this.subscription.playerAction);
            this.subscription.playerAction = this.mediaPlayerV2Service.refreshPlayer().subscribe();
        }
    }

    public onPlayPauseToggle() {
        if (this.mediaPlayerV2Service.isPaused() || this.mediaPlayerV2Service.isStopped()) {
            this.onPlay();
        } else {
            this.onPause();
        }
    }

    private onStop() {
        SOM.clearSubscriptions(this.subscription.playerAction);
        this.subscription.playerAction = this.mediaPlayerV2Service.stop().subscribe();
    }

    private onPrev() {
        SOM.clearSubscriptions(this.subscription.playerAction);
        this.subscription.playerAction = this.mediaPlayerV2Service.startOver().subscribe();
    }

    private onNext() {
        const channel = this.mediaPlayerV2Service.getChannel();
        if (!channel) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.playerAction);
        this.subscription.playerAction = this.mediaPlayerV2Service.playLive(channel.id).subscribe();
    }

    private onRecord() {
        if (this.profileService.getActiveProfile()?.profileType === 'kid') {
            return;
        }
        if (!this.tile) {
            return;
        }
        SOM.clearSubscriptions(this.subscription.record);
        this.subscription.record = this.tileService.record(this.tile).subscribe(() => {
            if (this.tile.mediaType === MediaTypes.NPVR) {
                this.navigationService.navigateHome();
            }
        });
    }

    private onChannelUp() {
        if (this.profileService.getActiveProfile()?.profileType === 'kid') {
            return;
        }
        this.confirmAction().then(() => {
            SOM.clearSubscriptions(this.subscription.playerAction);
            this.subscription.playerAction = this.mediaPlayerV2Service.channelUp().subscribe();
        }, () => {
        });
    }

    private onChannelDown() {
        if (this.profileService.getActiveProfile()?.profileType === 'kid') {
            return;
        }
        this.confirmAction().then(() => {
            SOM.clearSubscriptions(this.subscription.playerAction);
            this.subscription.playerAction = this.mediaPlayerV2Service.channelDown().subscribe();
        }, () => {
        });
    }

    public onSeek(direction: number, type: string = 's') {
        if (!this.tile) {
            return;
        }
        this.openOsd();
        const overlap = this.tileService.getOverlap(this.tile);
        const delta = type === 's' ? 15000 : Math.floor((this.tile.raw.duration + overlap.before + overlap.after) / 20);
        this.mediaPlayerV2Service.seek(direction > 0 ? delta : (-1) * delta);
    }

    public openOsd(closeOnTimeout = true, updateButtons = true) {
        if (this.isOsdDisabled()) {
            return;
        }
        this.stopCloseTimeout();
        if (!this.osdOpened) {
            this.osdOpened = true;
            this.osdToggled.emit(true);
            this.changeDetectorRef.detectChanges();
        } else if (updateButtons) {
            // update buttons
            if (this.osdComponent) {
                this.osdComponent.refreshActions();
            }
        }
        if (closeOnTimeout) {
            this.startCloseTimeout();
        }
        if (!this.settings.debug) {
            this.startDumbTimeout();
        }
    }

    private closeOsd() {
        this.stopCloseTimeout();
        this.stopDumbTimeout();
        if (this.mediaPlayerV2Service.isLocked()) {
            return;
        }
        if (this.osdOpened) {
            if (this.osdComponent) {
                this.osdComponent.closeContextMenu();
            }
            this.osdOpened = false;
            this.osdToggled.emit(false);
            this.changeDetectorRef.detectChanges();
        }
    }

    private onOsdPanelToggle() {
        if (this.osdOpened) {
            this.closeOsd();
        } else {
            this.openOsd(false);
        }
    }

    private onOsdContextMenuOpen() {
        if (this.mediaPlayerV2Service.isLocked()) {
            return;
        }
        if (!this.osdComponent) {
            this.openOsd(false);
        }
        if (this.osdComponent) {
            this.osdComponent.openContextMenu();
        }
    }

    private onOsdContextMenuToggle() {
        if (!this.osdComponent) {
            this.openOsd();
        }
        if (this.osdComponent) {
            this.osdComponent.toggleContextMenu();
        }
    }

    public toggleTeletext() {
        if (!this.mediaPlayerV2Service.getChannel()?.swttxIdent) {
            return;
        }
        if (!this.teletextOpened) {
            this.teletextOpened = true;
            this.changeDetectorRef.detectChanges();
            this.controllerService.registerActionKeys([ CommonKeys.GRP_BACK ], this.teletextIdent, () => {
                this.closeTeletext();
            });
        } else {
            this.closeTeletext();
        }
    }

    public closeTeletext() {
        this.teletextOpened = false;
        this.controllerService.unregisterStackLevel(this.teletextIdent);
        this.changeDetectorRef.detectChanges();
    }

    private watchPlayer() {
        this.subscription.watchPlayerPlay = this.mediaPlayerV2Service.onPlayStarted$.subscribe(() => {
            this.openOsd();
        });
        this.subscription.watchPlayerPause = this.mediaPlayerV2Service.onPause$.subscribe(() => {
            this.openOsd(false);
        });
        this.subscription.watchBroadcastGap = this.mediaPlayerV2Service.onBroadcastGapChanged$.subscribe(() => {
            //this.openOsd();
            console.log("\n-----------------------\nwatchBroadcastGap wants to open OSD menu\n------------------------\n");
        });
        if (this.platformHalService.appStateChanged$) {
            this.subscription.watchPlayerStop = this.platformHalService.appStateChanged$
                .pipe(filter((item) => item === 'playerStop'))
                .subscribe(() => {
                    this.onStop();
                });
        }
    }

    private watchTile() {
        this.subscription.onTilesChanged = this.mediaPlayerV2Service.onTileChanged$.pipe(switchMap(() => {
            this.tile = this.mediaPlayerV2Service.getCursorTile();
            if (!this.tile) {
                return of(null);
            }
            if (this.osdComponent) {
                this.osdComponent.resetAutoScrollLabel();
            }
            this.openOsd(this.mediaPlayerV2Service.isPlayerLoaded());
            this.changeDetectorRef.detectChanges();
            return this.tileService.getTileTagsUpdates(this.tile).pipe(startWith([]));
        })).subscribe(() => {
            if (this.tile) {
                this.tile.tags = this.tileService.getTileTags(this.tile);
            }
        });
    }

    private watchBuffering() {
        if (!this.mediaPlayerV2Service.buffering$) {
            return;
        }
        this.subscription.buffering = this.mediaPlayerV2Service.buffering$.subscribe((buffering) => {
            this.buffering = buffering;
            this.changeDetectorRef.detectChanges();
        });
    }

    private watchChannelSwitching() {
        this.channelSwitcherService.init();
        this.subscription.channelChanged = this.channelSwitcherService.channelSwitched$
            .pipe(
                switchMap((channel) => {
                    return from(this.confirmAction()).pipe(
                        switchMap(() => this.mediaPlayerV2Service.playLive(channel.id)),
                        catchError((error) => {
                            if (error.message === Errors.MODAL_CLOSE) {
                                return of(undefined);
                            }
                            throw error;
                        })
                    );
                }),
            )
            .subscribe();
    }

    private watchLock() {
        this.subscription.lockUpdated = this.mediaPlayerV2Service.onLockUpdated$.subscribe(() => {
            this.lockedReason = this.mediaPlayerV2Service.getLockedReason();
            if ([ 'ANDROID.MOBILE', 'WEB' ].indexOf(hal.platform) === -1) {
                this.unregisterLockControls();
                if (this.lockedReason === 'PARENTAL') {
                    this.registerLockControls();
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    private watchPowerControl() {
        this.subscription.powerOff = this.powerControlService.powerOff$
            .pipe(
                switchMap(() => {
                    if (this.mediaPlayerV2Service.isChromecastConnected() || this.mediaPlayerV2Service.isAirplayConnected()) {
                        return of(undefined);
                    }
                    if (this.backgroundPlay) {
                        if (hal.platform === 'MOBILE.IOS') {
                            if (this.mediaPlayerV2Service.isPaused() || this.mediaPlayerV2Service.isStopped()) {
                                return of(undefined);
                            }
                            return this.mediaPlayerV2Service.playBackground();
                        }
                        if (hal.platform === 'MOBILE.ANDROID') {
                            return of(undefined);
                        }
                    }
                    return this.mediaPlayerV2Service.sleep();
                })
            ).subscribe(() => {
                if (!this.mediaPlayerV2Service.getMediaPlayerConfig()?.onlyPlay) {
                    this.loggingService.log(LogEvents.EXIT);
                }
            });

        this.subscription.powerOn = this.powerControlService.powerOn$
            .pipe(
                switchMap(() => {
                    if (this.mediaPlayerV2Service.isChromecastConnected() || this.mediaPlayerV2Service.isAirplayConnected()) {
                        return of(undefined);
                    }
                    if (this.backgroundPlay) {
                        if (hal.platform === 'MOBILE.IOS') {
                            if (this.mediaPlayerV2Service.isPaused() || this.mediaPlayerV2Service.isStopped()) {
                                return of(undefined);
                            }
                            return this.mediaPlayerV2Service.playForeground();
                        }
                        if (hal.platform === 'MOBILE.ANDROID') {
                            return of(undefined);
                        }
                    }
                    if (this.mediaPlayerV2Service.isSleeping()) {
                        return this.mediaPlayerV2Service.wakeUp();
                    }
                    return of(undefined);
                }))
            .subscribe(() => {
                if (!this.mediaPlayerV2Service.getMediaPlayerConfig()?.onlyPlay) {
                    this.loggingService.log(LogEvents.RUN);
                }
            });
    }

    private watchConnection() {
        this.subscription.connectionDown = this.connectionCheckService.connectionDown$.pipe(switchMap(() => {
            return this.mediaPlayerV2Service.sleep();
        })).subscribe();
        this.subscription.connectionUp = this.connectionCheckService.connectionUp$.pipe(switchMap(() => {
            if (this.mediaPlayerV2Service.isSleeping()) {
                return this.mediaPlayerV2Service.wakeUp();
            }
            return of(undefined);
        })).subscribe();
    }

    private watchChromecast() {
        let connecting;
        this.subscription.chromeCastConnected = this.mediaPlayerV2Service.chromecastConnected$
            .pipe(
                skip(1),
                distinctUntilChanged(),
                switchMap((connectingState) => {
                    connecting = connectingState;
                    // disconnected
                    if (this.chromecastConnected || connecting) {
                        return this.mediaPlayerV2Service.refreshPlayer();
                    }
                    return of(null);
                }),
                tap(() => {
                    this.chromecastConnected = connecting;
                })
            ).subscribe();
    }

    private watchAirplay() {
        let connecting;
        this.subscription.airPlayConnected = this.mediaPlayerV2Service.airplayConnected$
            .pipe(
                skip(1),
                distinctUntilChanged(),
                switchMap((conectingState) => {
                    connecting = conectingState;
                    // disconnected
                    if (this.airplayConnected && !connecting) {
                        return this.mediaPlayerV2Service.refreshPlayer();
                    }
                    return of(undefined);
                }),
                tap(() => {
                    this.airplayConnected = connecting;
                })
            ).subscribe();
    }

    private startCloseTimeout() {
        this.stopCloseTimeout();
        this.ngZone.runOutsideAngular(() => {
            this.closeTimeout = setTimeout(() => {
                if (!this.osdComponent?.contextMenuOpened) {
                    this.closeOsd();
                }
            }, this.CLOSE_DELAY);
        });
    }

    private stopCloseTimeout() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
    }

    private startDumbTimeout() {
        this.stopDumbTimeout();
        this.ngZone.runOutsideAngular(() => {
            this.closeDumbTimeout = setTimeout(() => {
                if (!this.osdComponent?.contextMenuOpened) {
                    this.closeOsd();
                    this.closeDumbTimeout = null;
                }
            }, this.CLOSE_DUMB_DELAY);
        });
    }

    private stopDumbTimeout() {
        if (this.closeDumbTimeout) {
            clearTimeout(this.closeDumbTimeout);
            this.closeDumbTimeout = null;
        }
    }

    public onOpenPasswordModal() {
        this.ngZone.run(() => {
            from(this.modalsService.openPasswordModal()).pipe(switchMap(() => {
                this.parentalControlService.unlock();
                return this.mediaPlayerV2Service.refreshPlayer();
            })).subscribe({
                error: () => {
                }
            });
        });
    }

    private registerControls() {
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => this.onOsdPanelToggle());
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident, () => this.onOsdContextMenuOpen(), 'plo');
        this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.ident, (action) => {
            if (this.osdOpened && !this.mediaPlayerV2Service.isLocked()) {
                this.closeOsd();
            } else {
                this.controllerService.propagateAction(action);
            }
        });
        this.controllerService.registerActionKeys([ CommonKeys.LEFT, CommonKeys.REWIND ], this.ident, () => {
            this.onSeek(-1);
        });
        this.controllerService.registerActionKeys([ CommonKeys.LEFT, CommonKeys.REWIND ], this.ident, () => {
            this.onSeek(-1, 'h');
        }, 'h');
        this.controllerService.registerActionKeys([ CommonKeys.RIGHT, CommonKeys.FORWARD ], this.ident, () => {
            this.onSeek(1);
        });
        this.controllerService.registerActionKeys([ CommonKeys.RIGHT, CommonKeys.FORWARD ], this.ident, () => {
            this.onSeek(1, 'h');
        }, 'h');

        this.controllerService.registerActionKey(CommonKeys.PLAY, this.ident, () => this.onPlay());
        this.controllerService.registerActionKey(CommonKeys.PAUSE, this.ident, () => this.onPause());
        this.controllerService.registerActionKey(CommonKeys.STOP, this.ident, () => this.onStop());
        this.controllerService.registerActionKey(CommonKeys.PREV, this.ident, () => this.onPrev());
        this.controllerService.registerActionKey(CommonKeys.NEXT, this.ident, () => this.onNext());
        this.controllerService.registerActionKey(CommonKeys.GRP_REC, this.ident, () => this.onRecord());

        this.controllerService.registerActionKeys([ CommonKeys.GRP_INFO, CommonKeys.GRP_TV ], this.ident,
            () => this.onOsdContextMenuToggle());

        // if (this.mediaPlayerService.mediaPlayerType === MediaPlayerType.CHANNEL) {
        this.controllerService.registerActionKeys([ CommonKeys.UP, CommonKeys.CH_UP ], this.ident, () => {
            this.onChannelUp();
        });
        this.controllerService.registerActionKeys([ CommonKeys.DOWN, CommonKeys.CH_DOWN ], this.ident, () => {
            this.onChannelDown();
        });
        // }
        switch (hal.platform) {
            case 'TV.ANDROID':
                this.controllerService.registerActionKey(AndroidTVKeys.DOUBLE_VOLUME, this.ident, () => this.onPlayPauseToggle());
                break;
            case 'TV.TIZEN':
                this.controllerService.registerActionKey(TizenKeys.PAUSEPLAY_COMBO, this.ident, () => this.onPlayPauseToggle());
                break;
            case 'TV.ARRIS':
                this.controllerService.registerActionKey(ArrisKeys.PAUSEPLAY_COMBO, this.ident, () => this.onPlayPauseToggle());
                this.controllerService.registerActionKey(ArrisKeys.TXT, this.ident, () => this.toggleTeletext());
                break;
            case 'WEB':
                this.controllerService.registerActionKey(WebKeys.SPACE, this.ident, () => this.onPlayPauseToggle());
                break;
        }
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    private registerLockControls() {
        this.controllerService.registerActionKey(CommonKeys.OK, this.ident + '-lock', () => this.onOpenPasswordModal());
        this.controllerService.propagateActionKeys(
            [ CommonKeys.CH_UP, CommonKeys.CH_DOWN, CommonKeys.UP, CommonKeys.DOWN, ...GroupKeys.GRP_NUMBER ],
            this.ident + '-lock');
    }

    private unregisterLockControls() {
        this.controllerService.unregisterStackLevel(this.ident + '-lock');
    }

    private confirmAction(): Promise<boolean> {
        const confirmableTypes = [ MediaPlayerTypes.NPVR, MediaPlayerTypes.EPISODE, MediaPlayerTypes.VOD ];
        if (confirmableTypes.indexOf(this.mediaPlayerV2Service.getMediaPlayerType()) >= 0) {
            return this.modalsService.openConfirmModal({
                message: this.translateService.instant('GENERAL.PLAY_LIVE_CONFIRM_MESSAGE')
            });
        }
        return Promise.resolve(true);
    }

    /* Mouse section */
    private watchMouse() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription.onMove = merge(
                hal.platform !== 'WEB' ? of(null) : fromEvent(document, 'mousemove'),
                fromEvent(document, 'touchmove', { passive: true })
            ).subscribe(event => {
                if (!event) {
                    return;
                }
                this.openOsd(true, false);
            });
            this.subscription.onClick = fromEvent(document, 'click').pipe(
                // due to cordova 'click' event when working with remote controller
                skipUntil(fromEvent(document, 'mousemove'))
            ).subscribe(() => {
                this.onClick();
            });
        });
    }

    public onClick() {
        if (this.osdComponent?.preventClickEvent) {
            this.osdComponent.preventClickEvent = false;
            return;
        }
        if (this.osdOpened) {
            this.closeOsd();
        } else {
            this.openOsd(false);
        }
    }

    public onContextMenuToggled(opened: boolean) {
        if (opened) {
            this.stopCloseTimeout();
            this.stopDumbTimeout();
        } else {
            if (!this.settings.debug) {
                this.startDumbTimeout();
            }
        }
    }

    /* Fullscreen */

    private watchFullscreen() {
        this.subscription.onToggleFullscreen = this.mediaPlayerV2Service.onToggleFullscreen$.subscribe((openFullscreen) => {
            try {
                if (openFullscreen) {
                    const reqFullscreen = this.getReqFullScreen();
                    if (reqFullscreen) {
                        reqFullscreen.call(this.elementRef.nativeElement);
                    } else {
                        // TODO: make it full screen with    classic css solution
                    }
                } else if ((document as any).fullscreenElement ||
                    (document as any).webkitFullscreenElement ||
                    (document as any).mozFullScreenElement) {
                    const cancelFullsceen = this.getExitFullScreen();
                    if (cancelFullsceen) {
                        cancelFullsceen.call(document);
                    } else {
                        // TODO: make it back from full screen with classic css solution
                    }
                }
            } catch { // catching fullscreen error
            }
        });
    }

    private getReqFullScreen() {
        const element = document.documentElement;
        return element.requestFullscreen ||
            (element as any).webkitRequestFullscreen ||
            (element as any).mozRequestFullScreen ||
            (element as any).msRequestFullscreen;
    }

    private getExitFullScreen() {
        return document.exitFullscreen ||
            (document as any).webkitExitFullscreen ||
            (document as any).mozCancelFullScreen ||
            (document as any).msExitFullscreen;
    }

    @HostListener('window:beforeunload')
    public ngOnDestroy(): void {
        this.mediaPlayerV2Service.destroy();
        this.channelSwitcherService.destroy();
        this.componentRegisterService.unregisterComponent(this.ident);
        this.loggingService.log(LogEvents.RUN);
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
    }
}

