import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenSaverService } from '@kuki/global/shared/services/screen-saver.service';
import { NpvrStatsModule } from '@kuki/global/features/npvr-stats/npvr-stats.module';
import { ProfileIconModule } from '@kuki/global/features/profile-icon/profile-icon.module';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';
import { TileModule } from '../../../web/features/tile/tile.module';
import { ScreenSaverComponent } from './screen-saver.component';
import { RatingModule } from '@kuki/global/features/rating/rating.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';

@NgModule({
    declarations: [ ScreenSaverComponent ],
    imports: [
        CommonModule,
        RatingModule,
        AutoScrollContainerModule,
        ImageLinkFactoryModule,
        NpvrStatsModule,
        ProfileIconModule,
        TileModule
    ],
    exports: [
        ScreenSaverComponent
    ],
    providers: [
        ScreenSaverService
    ]
})
export class ScreenSaverModule {
}
