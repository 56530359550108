import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VolumeV2Component } from './volume-v2.component';
import { VolumeService } from '@kuki/global/features/volume-v2/volume.service';


@NgModule({
    declarations: [ VolumeV2Component ],
    imports: [
        CommonModule
    ],
    exports: [
        VolumeV2Component
    ],
    providers: [
        VolumeService
    ]
})
export class VolumeV2Module {
}
